@charset "UTF-8";

//*------------------------------------*//
//	Base
//*------------------------------------*//

@use 'base/reset';
@use 'base/default';






//*------------------------------------*//
//	page
//*------------------------------------*//
@use 'page/common';
@use 'page/index';
@use 'page/article';
@use 'page/news';
@use 'page/brand';
@use 'page/life';

@use 'page/business';
@use 'page/business06';

@use 'page/interview';

@use 'page/education';
@use 'page/education06';
@use 'page/education07';

@use 'page/reasons';

@use 'page/higashihiroshima';
@use 'page/higashihiroshima03';
@use 'page/higashihiroshima04';
@use 'page/higashihiroshima05';




@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;500;600&display=swap');
