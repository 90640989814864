@use '../base/vars' as *;

.brand{
    padding-top: 200px;
    
    @include media_query(){
        padding-top: 80px;
    }
    
    .brand_head{
        h1{
            display: flex;
            flex-direction: column;
            align-items: center;
            @include rem(28);
            padding-bottom: 40px;
            
            @include media_query(){
                text-align: center;
                line-height: 1.5;
                padding-bottom: 75px;
            }
            
            &::after{
                content: "";
                width: 100px;
                height: 1px;
                background-color: #4C4948;
                margin-top: 40px;
                
                @include media_query(){
                    margin-top: 30px;
                }
            }
        }
    }
}

.brand_section{
    text-align: center;
    
    img{
        width: 100%;
        max-width: 245px;
        height: auto;
        
        @include media_query(){
            max-width: 157px;
        }
    }
    
    .brand_logo + p{
        margin-top: 80px;
        
        @include media_query(){
            margin-top: 60px;
        }
    }
    
    p{
        @include rem(18);
        line-height: 2.3;
        
        @include media_query(){
            text-align: left;
            padding: 0 30px;
            @include rem(16);
        }
        
        strong{
            display: block;
            padding: 15px 0;
            @include rem(28);
            line-height: 1.8;
            
            @include media_query(){
                @include rem(22);
                line-height: 1.6;
            }
        }
    }
}


.brand_objective{
    background-color: #E3F2FC;
    padding: 100px 0;
    margin-top: 160px;
    
    @include media_query(){
        margin-top: 100px;
        padding: 65px 30px;
    }
    
    .brand_objective_inner{
        max-width: 900px;
        margin: 0 auto;
        
        h2{
            color: #5E7BB1;
            padding: 15px 0;
            border-top: solid 3px #fff;
            border-bottom: solid 3px #fff;
            text-align: center;
            margin-bottom: 80px;
            @include rem(28);
            
            @include media_query(){
                @include rem(23);
                margin-bottom: 60px;
            }
        }
        
        & + .brand_objective_inner{
            margin-top: 90px;
            
            @include media_query(){
                margin-top: 60px;
            }
            
            &::before{
                content: "";
                display: block;
                width: 103px;
                height: 43px;
                margin: 0 auto 80px;
                background-image: url(../img/brand/brand_section_arrow.png);
                background-size: cover;
                
                @include media_query(){
                    width: 83px;
                    height: 34px;
                }
            }
        }
    }
    
    .brand_objective_inner_ul{
        max-width: 720px;
        margin: 0 auto;
        counter-reset: num;
        
        li{
            display: flex;
            align-items: center;
            list-style: none;
            background-color: #fff;
            border-radius: 15px;
            padding: 20px 35px;
            @include rem(24);
            font-weight: bold;
            letter-spacing: .08em;
            
            @include media_query(){
                align-items: flex-start;
                line-height: 1.4;
                padding: 18px 16px;
                
                span{
                    flex: 1;
                }
            }
            
            & + li{
                margin-top: 40px;
                
                @include media_query(){
                    margin-top: 20px;
                }
            }
            
            &::before{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                counter-increment: num;
                content: counter(num);
                background-color: #5E7BB1;
                @include rem(20);
                color: #fff;
                margin-right: 25px;
                font-weight: normal;
                
                @include media_query(){
                    margin-right: 10px;
                    @include rem(16);
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }
}




.brand_objective_wrap{
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    
    @include media_query(){
        display: block;
        margin: 0;
    }
    
    .brand_objective_item{
        background-color: #fff;
        margin-left: 16px;
        margin-bottom: 16px;
        padding: 50px 35px 45px;
        
        @include media_query(){
            padding: 40px 18px;
        }
        
        & + .brand_objective_item{
            @include media_query(){
                margin-top: 14px !important;
            }
        }
        
        &:not(:nth-child(3)){
            width: calc(50% - 16px);
            margin-left: 16px;
            
            @include media_query(){
                width: 100%;
                margin: 0;
            }
        }
        
        &:nth-child(3){
            width: calc(100% - 16px);
            margin-left: 16px;
            
            @include media_query(){
                width: 100%;
                margin: 0;
            }
        }
        
        h3{
            @include rem(24);
            text-align: center;
            margin-bottom: 40px;
            letter-spacing: .05em;
            
            @include media_query(){
                @include rem(22);
                margin-bottom: 30px;
            }
        }
        
        p{
            @include rem(18);
            line-height: 1.6;
        }
        
        .pagelink{
            margin-top: 30px;
            
            a{
                display: flex;
                align-items: center;
                color: #89B9EE;
                text-decoration: none;
                
                &::before{
                    content: "";
                    width: 20px;
                    height: 16px;
                    background-image: url(../img/brand/icon_link.png);
                    background-size: cover;
                    margin-right: 15px;
                }
            }
        }
        
        .pagelink_r{
            a{
                justify-content: flex-end;
            }
        }
        
        .brand_objective_item_img{
            margin-top: 40px;
            text-align: center;
            
            img{
                width: 100%;
                max-width: 100%;
                height: auto;
            }
            
            .brand01 img{
                max-width: 148px;
            }
            
            .brand02 img{
                max-width: 278px;
            }
            
            .brand03{
                position: relative;
                max-width: 543px;
                margin: 0 auto;
                
                .img03x{
                    position: absolute;
                    top: -50px;
                    left: 12%;
                    padding-top: 30px;
                    
                    img{
                        max-width: 70px;
                        
                        @include media_query(){
                            max-width: 50px;
                        }
                    }
                    
                    @keyframes firstview03{
                        0%{
                            top: -50px;
                            left: 12%;
                        }
                        50%{
                            top: -80px;
                            left: 12.5%;
                        }
                        100%{
                            top: -50px;
                            left: 12%;
                        }
                    }
                    
                    animation-name: firstview03;
                    animation-duration: 3s;
                    animation-iteration-count: infinite;
                }
                
                .img03{
                    img{
                        max-width: 543px;
                    }
                }
            }
            
            .brand04{
                width: 100%;
                max-width: 310px;
                margin: 0 auto;
                aspect-ratio: 310 / 178;
                object-fit: contain;
                
                background-repeat: no-repeat;
                background-position: top left;
                background-size: contain;
                
                @keyframes firstview04{
                    0%{
                        background-image: url(../img/brand/img04_off.png);
                    }
                    49.9%{
                        background-image: url(../img/brand/img04_off.png);
                    }
                    50%{
                        background-image: url(../img/brand/img04_on.png);
                    }
                    100%{
                        background-image: url(../img/brand/img04_on.png);
                    }
                }
                
                animation-name: firstview04;
                animation-duration: 1.15s;
                animation-iteration-count: infinite;
            }
            
            .brand05 img{
                max-width: 285px;
            }
        }
        
        .pl{
            @include rem(18);
            text-align: center;
            
            @include media_query(){
                @include rem(16);
            }
        }
        
        .inner_item_wrap{
            display: flex;
            margin-left: -18px;
            margin-top: 40px;
            
            @include media_query(){
                display: block;
                margin: 30px 0 0;
            }
            
            .inner_item{
                width: 33.333%;
                padding-left: 18px;
                
                @include media_query(){
                    width: 100%;
                    padding: 0;
                }
                
                & + .inner_item{
                    margin-left: 18px;
                    border-left: solid 1px #C9C8C8;
                    
                    @include media_query(){
                        margin: 25px 0 0;
                        padding-top: 25px;
                        border: none;
                        border-top: solid 1px #C9C8C8;
                    }
                }
                
                h4{
                    margin-bottom: 20px;
                    @include rem(18);
                    letter-spacing: .08em;
                }
                
                p{
                    @include rem(16);
                }
            }
        }
    }
}

.brand_objective_item_ul{
    margin-top: 28px;
    
    h4{
        margin-bottom: 12px;
    }
    
    li{
        list-style: none;
        @include rem(16);
        line-height: 1.6;
        text-indent: -1em;
        padding-left: 1em;
        
        & + li{
            margin-top: .3em;
        }
    }
}

.last_p{
    @include rem(28);
    text-align: center;
    font-weight: bold;
    margin-top: 100px;
    line-height: 1.75;
    letter-spacing: .02em;
    
    @include media_query(){
        margin-top: 80px;
        @include rem(22);
    }
}

.br_sp{
    display: none;
    
    @include media_query(){
        display: block;
    }
}

.br_pc{
    display: block;
    
    @include media_query(){
        display: none;
    }
}