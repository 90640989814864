@use '../base/vars' as *;

.business03 {
  margin-top: 80px;

  img {
    max-width: 100%;
    height: auto;
  }

  .sp {
    @media (min-width:641px) {
      display: none;
    }
    @include media_query() {
      display: block;
    }
  }

  .business03_title {
    position: relative;
    @media (min-width:641px) {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .title_icon {
      margin-left: auto;
      margin-right: auto;
      width: 112px;
      @media (min-width:641px) {
        position: absolute;
        right: 0;
        top: 0;
      }
      @include media_query() {
        margin-top: 30px;
      }
    }
  }
  //.business03_title

  .business03_wrap {
    margin-top: 65px;

    p {
      @include rem(18);
      line-height: 1.8;
    }

    .article_title {
      margin-bottom: 30px;
    }

    .business03_images {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;

      .img02 {
        margin-right: 40px;
        @include media_query() {
          margin-right: 20px;
        }
      }

      .img02,
      .img03 {
        @include media_query() {
          width: calc(50% - 10px);
        }
      }
    }

    .institution {
      margin-top: 80px;
      display: flex;
      flex-wrap: wrap;
      @include media_query() {
        flex-direction: column;
        margin-top: 60px;
      }

      .institution_name {
        line-height: 1.6;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100px;

        > * {
          @include rem(21);
          font-weight: 600;
        }
      }

      .institution_box {
        background-color: #E7F1FC;
        border-radius: 5px;
        padding: 25px 25px 40px;
        width: calc(50% - 30px);
        @include media_query() {
          width: 100%;
        }

        &:not(:first-child) {
          @media (min-width:641px) {
            margin-left: 60px;
          }
          @include media_query() {
            margin-top: 30px;
          }
        }

          display: block;
          text-decoration: none;
          color: #000;
          position: relative;
          &::before,
          &::after{
            position: absolute;
            content: '';
            height: 2px;
            line-height: 2px;
            background-color: #5E7BB1;
            transition: 0.5s;
          }

          &::before {
            width: 10px;
            bottom: 28px;
            right: 25px;
            transform: rotate(35deg);
          }

          &::after {
            width: 70px;
            bottom: 25px;
            right: 26px;
          }

          &:hover {
            opacity: 0.7;

            &::before {
              right: 15px;
            }

            &::after {
              right: 15px;
            }
          }

      }//.institution_box
    }
    //.institution

    .institution_list {
      margin-top: 100px;
      border-bottom: 1px solid #C9C8C8;
      line-height: 1.4;
      @include media_query() {
        margin-top: 60px;
      }

      .item {
        text-decoration: none;
        padding: 30px 100px 30px 0;
        border-top: 1px solid #C9C8C8;
        @include media_query() {
          padding: 20px 45px 20px 0;
        }

        &-link {
          display: block;
          color: #000;
          text-decoration: none;
          position: relative;
          transition: .3s;
          &::before,
          &::after
           {
            position: absolute;
            content: '';
            height: 2px;
            line-height: 2px;
            background-color: #5E7BB1;
            transition: .3s;
          }

          &::before {
            width: 10px;
            top: calc(50% - 3px);
            right: 30px;
            transform: rotate(35deg) translateY(-50%);
            @include media_query() {
              right: 4px;
            }
          }
          &::after
           {
            width: 70px;
            top: 50%;
            right: 31px;
            transform: translateY(-50%);
            @include media_query() {
              right: 5px;
              width: 30px;
            }
          }

          &:hover {
            opacity: 0.7;

            &::before,
            &::after {
              right: 0;
            }
          }
        }
        //&-link
      }
      //.item
      .plural {
        display: flex;
        padding-top: 30px;
        padding-bottom: 30px;
        border-top: 1px solid #C9C8C8;
        @include media_query() {
          flex-direction: column;
          padding-top: 15px;
          padding-bottom: 15px;
        }

        .item-left {
          width: 60%;
          @include media_query() {
            width: 100%;
          }
        }

        .item-right {
          width: calc(40% - 40px);
          margin-left: 40px;
          @include media_query() {
            width: 100%;
            margin-left: 0;
            margin-top: 30px;
          }

          .item-link {
            padding: 15px 100px 15px 0;
            @include media_query() {
              padding: 10px 35px 10px 0;
            }
          }
        }
      }

      .summary {
        @include rem(16);
      }

      .name {
        margin-top: 10px;
        @include rem(21);
        font-weight: 600;
        @include media_query() {
          @include rem(18);
        }
      }
    }
    //.institution_list
  }
  //.business03_wrap
}
//.business03

.business04 {
  img {
    max-width: 100%;
    height: auto;
  }

  .img01,
  .img02 {
    @include media_query() {
      margin-top: 30px;
    }
  }

  .img03 {
    max-width: 798px;
    width: 100%;
  }

  .img04 {
    margin-top: 50px;
    max-width: 568px;
    width: 100%;
  }

  .business04_img {
    margin-top: 60px;
    text-align: center;
  }

  &.article_body_contents ul {
    background: none;
    margin-left: -15px;
    padding: 30px;

    li {
      margin-left: 15px;
      list-style: none;
      @include rem(15);
      line-height: 2;
      @include media_query() {
        @include rem(15);
      }

      &::before {
        content: "■";
        @include rem(15);
        margin-right: 0;
      }
    }
  }

  .business04_flex {
    display: flex;
    flex: wrap;
    align-items: center;
    margin-top: 90px;
    @include media_query() {
      flex-direction: column;
    }

    .under_title_box {
      max-width: 400px;
      width: 100%;

      .supplement {
        text-align: center;
        margin-top: 20px;
        @include rem(21);
        color: #5E7BB1;
      }
    }
  }
  //.business04_flex

  .business04_h {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    > * {
      display: inline-block;
      @include rem(24);
      line-height: 1.5;
      padding: 0.8em;
      @include media_query() {
        @include rem(18);
      }
    }

    &.h_border > * {
      border: 1px solid #696969;
      margin-top: 150px;
      @include media_query() {
        margin-top: 80px;
      }
    }

    &.h_bg > * {
      max-width: 660px;
      width: 100%;
      background-color: #A5E5F7;
      margin-top: 160px;
      @include media_query() {
        margin-top: 80px;
      }
    }
  }
  //business04_h_border

  .business04_rate {
    margin-top: 30px;
    max-width: 660px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #C9C8C8;
    @include media_query() {
      padding: 0 15px 15px;
    }

    .business04_rate_list {
      li {
        display: inline-block;
      }

      .item {
        &01::before {
          color: #A7C9C2;
        }

        &02::before {
          color: #548BD8;
        }

        &03::before {
          color: #A5E5F7;
        }

        &04::before {
          color: #47BDCC;
        }

        &05::before {
          color: #00A75F;
        }

        &06::before {
          color: #A9DB5B;
        }

        &07::before {
          color: #FFD827;
        }

        &08::before {
          color: #F59B0C;
        }

        &09::before {
          color: #ED694F;
        }

        &10::before {
          color: #F3A9C9;
        }

        &11::before {
          color: #B283D1;
        }

        &12::before {
          color: #CECFCF;
        }
      }
      //.item
    }
  }
  //.business04_rate
  .business04_supplement {
    max-width: 660px;
    margin-left: auto;
    margin-right: auto;
    text-align: right;
    width: 100%;
    @include rem(13);
    line-height: 1.8;
    letter-spacing: 0.04em;
    margin-top: 40px;
  }

  .business04_see {
    margin-top: 90px;
    @include media_query() {
      margin-top: 60px;
    }

    .business04_see_content {
      margin-top: 45px;
      @include rem(18);
      line-height: 1.6;
    }
  }

  .link_movie {
    max-width: 490px;
    width: 100%;
    margin: 90px auto;
    background-color: #5E7BB1;
    padding: 40px 50px;
    border-radius: 10px;
    @include media_query() {
      margin: 50px auto;
    }

    &_img {
      text-align: center;

      img {
        width: 80px;
        height: auto;
      }
    }

    &_title {
      @include rem(24);
      line-height: 1.5;
      color: #fff;
      margin-top: 20px;
      text-align: center;
    }

    .btn_black {
      margin-top: 30px;

      a {
        background-color: #89B9EE;
      }
    }
  }
  //.link_movie
}
//.business04

.business05 {
  .supplement {
    text-align: center;
    margin-top: 20px;
    @include rem(13);
    color: #5E7BB1;
  }

  .business05_img {
    margin-top: 40px;
    text-align: center;
    
    img{
      @include media_query(){
        width: 100%;
        height: auto;
      }
    }
  }
  
  .business05_img_over{
    @include media_query(){
      width: 100%;
      height: auto;
      overflow: scroll;
    }
    
    img{
      max-width: 100%;
      height: auto;
      
      @include media_query(){
        width: 640px;
        max-width: 640px;
      }
    }
  }
  
  .business05_wrap {
    margin-top: 80px;
  }

  .business05_h {
    text-align: center;
    margin-top: 60px;

    > * {
      display: inline-block;
      @include rem(24);
      padding: 0.8em;
      border: 1px solid #696969;
      @include media_query() {
        @include rem(18);
      }
    }
  }
}
//.business05
