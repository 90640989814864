@use '../base/vars' as *;


.article_body .higashihiroshima05{
    
    img{
        width: 100%;
        height: auto;
    }
    
    p{
        @include rem(16);
        line-height: 1.8;
        
        small{
            @include rem(13);
        }
    }
    
    .intro{
        margin-top: 35px;
        @include rem(16);
        line-height: 2;
        
        span{
            @include rem(17);
            font-weight: bold;
        }
        
        a{
            color: #333;
        }
    }
    
    .qa{
        margin-top: 60px;
        
        .qa_inner{
            & + .qa_inner{
                margin-top: 40px;
            }
            
            dt,dd{
                display: grid;
                grid-template-columns: 40px 1fr;
                gap: 20px;
                line-height: 1.8;
                
                &::before{
                    content: "";
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: contain;
                }
            }
            
            dt{
                @include rem(18);
                font-weight: bold;
                
                &::before{
                    background-image: url(../img/higashihiroshima/higashihiroshima05/icon_q.png);
                }
                
                & + dd{
                    margin-top: 20px;
                }
            }
            
            dd{
                @include rem(16);
                
                &::before{
                    background-image: url(../img/higashihiroshima/higashihiroshima05/icon_a.png);
                }
                
                img{
                    margin-top: 70px;
                    
                    @include media_query(){
                        margin-top: 30px;
                    }
                }
            }
        }
    }
    
    .img02_wrap{
        margin-top: 30px;
        padding-left: 50px;
        
        @include media_query(){
            max-width: 100%;
            overflow: scroll;
        }
        
        img{
            @include media_query(){
                width: 650px;
            }
        }
    }
    
    .about_tg{
        margin-top: 75px;
        background-color: #FFF4DF;
        padding: 30px 25px;
        
        @include media_query(){
            padding: 20px;
        }
        
        h2{
            @include rem(24);
            text-align: center;
            color: #EF8200;
            margin-bottom: 30px;
            line-height: 1.5;
        }
        
        .about_tg_inner{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 25px;
            
            @include media_query(){
                grid-template-columns: 1fr;
            }
            
            p{
                @include rem(16);
                line-height: 1.8;
            }
        }
    }
    
    .section_title{
        max-width: 520px;
        margin: 0 auto 60px;
        color: #33B495;
        @include rem(26);
        line-height: 1.7;
        border-top: solid 10px #33B495;
        border-bottom: solid 10px #33B495;
        padding: 20px 0;
        
        small{
            display: block;
            font-weight: normal;
            @include rem(16);
            
            &.interview{
                @include rem(20);
                color: #EF8200;
            }
        }
    }
    
    .inner_title{
        text-align: center;
        @include rem(24);
        color: #33B495;
        margin-bottom: 30px;
        line-height: 1.5;
        
        span{
            display: block;
            text-align: center;
            @include rem(16);
            line-height: 1.6;
            margin-bottom: 10px;
            color: #333;
            font-weight: normal;
        }
    }
    
    .section_title_s{
        color: #33B495;
        @include rem(21);
        line-height: 1.5;
        margin: 70px 0 20px;
    }
    
    .section_title_m{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @include rem(21);
        line-height: 1.5;
        margin: 60px 0 55px;
        
        &::after{
            margin-top: 30px;
            content: "";
            width: 85px;
            height: 2px;
            background-color: #33B495;
        }
    }
    
    .orange_bg{
        background-color: #FFF4DF;
        padding: 20px;
        margin-top: 30px;
        
        p{
            line-height: 1.8;
        }
    }
    
    .green_bg{
        margin-top: 30px;
        background-color: #EAF4F0;
        padding: 30px 25px 20px;
        
        @include media_query(){
            padding: 20px;
        }
        
        h5{
            text-align: center;
            
            span{
                display: inline-block;
                border-radius: 30px;
                background-color: #33B495;
                color: #fff;
                padding: 10px 20px;
            }
        }
        
        .green_bg_imgs{
            margin-top: 30px;
            
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
            
            @include media_query(){
                grid-template-columns: 1fr;
            }
        }
    }
    
    .section02{
        margin-top: 100px;
        
        .img05{
            margin-top: 60px;
            
            @include media_query(){
                width: 100%;
                overflow: scroll;
                
                img{
                    width: 650px;
                }
            }
        }
    }
    
    .section03{
        margin-top: 100px;
        
        .img06{
            max-width: 570px;
            margin: 56px auto 0;
            
            @include media_query(){
                width: 100%;
                overflow: scroll;
                
                img{
                    width: 500px;
                }
            }
        }
        
        .img07{
            max-width: 600px;
            margin: 60px auto 0;
        }
        
        .img08{
            max-width: 430px;
            margin: 60px auto 0;
        }
    }
    
    .section_inner{
        & + .section_inner{
            margin-top: 60px;
            border-top: dashed 2px #33B495;
        }
    }
    
    .section04{
        margin-top: 100px;
        
        .img09{
            max-width: 600px;
            margin: 56px auto 0;
        }
        
        .img10{
            max-width: 600px;
            margin: 56px auto 0;
        }
        
        .section_it{
            margin-top: 60px;
            display: grid;
            grid-template-columns: 1fr 200px;
            gap: 30px;
            
            @include media_query(){
                grid-template-columns: 1fr;
            }
            
            .section_it_text{
                @include media_query(){
                    .section_title_s{
                        margin-top: 20px;
                    }
                }
            }
            
            .section_it_img{
                padding-top: 125px;
                
                @include media_query(){
                    max-width: 250px;
                    margin: 0 auto;
                    order: -1;
                    padding: 0;
                }
            }
        }
    }
    
    .section05{
        margin-top: 100px;
        
        .img15{
            max-width: 610px;
            margin: 60px auto 0;
            display: grid;
            grid-template-columns: 1fr 146px;
            gap: 60px;
            align-items: center;
            
            @include media_query(){
                margin-top: 30px;
                gap: 20px;
            }
        }
        
        .img17{
            max-width: 610px;
            margin: 60px auto 0;
            display: grid;
            grid-template-columns: 136px 1fr;
            gap: 60px;
            align-items: center;
            
            @include media_query(){
                margin-top: 20px;
                gap: 20px;
            }
        }
        
        .img19{
            max-width: 372px;
            margin: 30px auto 0;
        }
    }
    
    .section06{
        margin-top: 100px;
        
        .sp_br_none{
            @include media_query(){
                br{
                    display: none;
                }
            }
        }
        
        
        .section06_interview_inner{
            .section06_interview_img{
                max-width: 220px;
                margin: 0 auto 60px;
            }
            
            .section06_interview_head{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                align-items: center;
                gap: 40px;
                
                @include media_query(){
                    grid-template-columns: 1fr;
                    gap: 20px;
                }
                
                .section06_interview_head_img{
                    
                }
                
                .section06_interview_head_text{
                    text-align: left;
                    .position{
                        @include rem(15);
                    }
                    
                    .name{
                        font-weight: bold;
                        @include rem(24);
                        
                        small{
                            @include rem(15);
                        }
                    }
                    
                    .btn_video{
                        margin-top: 20px;
                        
                        a{
                            position: relative;
                            display: grid;
                            place-content: center;
                            font-weight: bold;
                            width: 100%;
                            max-width: 240px;
                            height: 60px;
                            border-radius: 30px;
                            @include rem(15);
                            color: #EF8200;
                            border: solid 2px #EF8200;
                            
                            transition: background-color .25s, color .25s;
                            
                            &:hover{
                                background-color: #EF8200;
                                color: #fff;
                                
                                &::after{
                                    border-color: #fff;
                                }
                            }
                            
                            &::after{
                                position: absolute;
                                right: 30px;
                                top: 50%;
                                transform: rotate(45deg) translateY(-50%);
                                display: block;
                                content: "";
                                width: 8px;
                                height: 8px;
                                border-top: solid 2px #EF8200;
                                border-right: solid 2px #EF8200;
                                
                                transition: border-color .25s;
                            }
                        }
                    }
                }
            }
        }
        
        
        
    }
}