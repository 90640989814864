@use '../base/vars' as *;

.vvh{
    position: absolute;
    z-index: -99;
    visibility: hidden;
    opacity: 0;
}

.firstview{
    position: fixed;
    z-index: -1;
    margin: 0 auto;
    width: 100vw;
    height: 100vh;
    
    img{
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        object-position: left top;
        
        @include media_query(){
            object-fit: contain;
        }
    }
    // background-repeat: no-repeat;
    // background-position: top 20px left;
    // background-size: cover;
    // background-image: url(../img/firstview_bg.gif);
    
    // @include media_query(){
    //     background-size: contain;
    //     background-image: url(../img/firstview_bg_sp.gif);
    // }
    
    // @keyframes firstview{
    //     0%{
    //         background-image: url(../img/firstview_bg_off.png);
    //     }
    //     49.9%{
    //         background-image: url(../img/firstview_bg_off.png);
    //     }
    //     50%{
    //         background-image: url(../img/firstview_bg_on.png);
    //     }
    //     100%{
    //         background-image: url(../img/firstview_bg_on.png);
    //     }
    // }
    
    // @keyframes firstviewsp{
    //     0%{
    //         background-image: url(../img/firstview_bg_sp_off.png);
    //     }
    //     49.9%{
    //         background-image: url(../img/firstview_bg_sp_off.png);
    //     }
    //     50%{
    //         background-image: url(../img/firstview_bg_sp_on.png);
    //     }
    //     100%{
    //         background-image: url(../img/firstview_bg_sp_on.png);
    //     }
    // }
    
    // animation-name: firstview;
    // animation-duration: 1.15s;
    // animation-iteration-count: infinite;
    
    // @include media_query(){
    //     animation-name: firstviewsp;
    //     background-position: center left;
    //     background-size: contain;
    // }
    
}
.scroll{
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    z-index: 1;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    a{
        font-family: 'Barlow Semi Condensed', sans-serif;
        font-weight: bold;
        @include rem(12);
        text-decoration: none;
        color: #606060;
    }
    
    &::after{
        display: block;
        content: "";
        width: 1px;
        height: 44px;
        margin-top: 10px;
        background-color: #606060;
        
        animation: scrolldown 1.8s infinite;
    }
    
    @keyframes scrolldown {
        0% {
            transform: scale(1, 0);
            transform-origin: right top;
        }
        30% {
            transform-origin: right top;
            transform: scale(1, 1);
        }
        70% {
            transform-origin: right bottom;
            transform: scale(1, 1);
        }
        100% {
            transform: scale(1, 0);
            transform-origin: right bottom;
        }
    }

}




.page_wrap{
    padding-top: 100vh;
    
    .page_wrap_inner{
        position: relative;
    }
}





.introduction{
    background-color: #fff;
    
    .introduction_inner{
        max-width: 900px;
        margin: 0 auto;
        padding: 90px 20px 130px;
        
        @include media_query(){
            padding: 85px 30px;
        }
        
        h2{
            @include rem(43);
            line-height: 1.7;
            text-align: center;
            margin-bottom: 50px;
            color: #4C4948;
            letter-spacing: .1em;
            
            @include media_query(){
                @include rem(24);
                line-height: 1.6;
            }
        }
        
        p{
            @include rem(18);
            font-weight: normal;
            line-height: 2.6;
            text-align: center;
            line-height: 3;
            color: #4C4948;
            letter-spacing: .1em;
            
            @include media_query(){
                @include rem(16);
                text-align: left;
                line-height: 2;
            }
            
            & + p{
                margin-top: 2em;
                
                @include media_query(){
                    margin-top: 1em;
                }
            }
        }
        
        .btn_about{
            display: flex;
            justify-content: center;
            margin-top: 80px;
            
            @include media_query(){
                margin-top: 40px;
            }
            
            a{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                max-width: 400px;
                height: 80px;
                background-color: #89B9EE;
                border-radius: 60px;
                text-decoration: none;
                color: #fff;
                @include rem(24);
                letter-spacing: .08em;
                
                transition: background-color .25s;
                
                @include media_query(){
                    @include rem(14);
                    max-width: 245px;
                    height: 50px;
                }
                
                &:hover{
                    background-color: #5E7BB1;
                }
                
                img{
                    position: relative;
                    top: -5px;
                    left: 5px;
                    width: 100%;
                    max-width: 41px;
                    height: auto;
                    
                    @include media_query(){
                        max-width: 25px;
                    }
                }
            }
        }
    }
}





.pickup{
    background-color: #fff;
    
    & + .news{
        padding: 100px 0 0;
    }
    
    &::before{
        display: block;
        content: "";
        width: 100%;
        height: 49px;
        background-image: url(../img/pickup_bg_top.png);
        background-size: auto 49px;
        background-color: #fff;
    }
    &::after{
        display: block;
        content: "";
        width: 100%;
        height: 49px;
        background-image: url(../img/pickup_bg_bottom.png);
        background-size: auto 49px;
        background-color: #fff;
    }
    
    .pickup_inner{
        background-color: #F2F5F8;
        padding: 100px 20px;
        
        @include media_query(){
            padding: 50px;
        }
    }
    
    .pickup_wrap{
        display: flex;
        justify-content: center;
        gap: 48px;
        margin-top: 60px;
        
        @include media_query(){
            flex-direction: column;
            gap: 25px;
        }
        
        .pickup_item{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: calc(50% - 48px);
            max-width: 471px;
            text-decoration: none;
            color: $font-color;
            aspect-ratio: 471 / 468;
            padding: 20px 50px 0;
            
            background-image: url(../img/pickup_item_bg.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            
            transition: opacity .25s;
            
            &:hover{
                opacity: .7;
            }
            
            @include media_query(){
                width: 100%;
            }
            
            img{
                width: 100%;
                height: auto;
                vertical-align: bottom;
            }
            
            &.lifestyle{
                img{
                    max-width: 309px;
                }
            }
            
            &.business{
                img{
                    max-width: 287px;
                }
            }
            
            p{
                @include rem(26);
                font-weight: bold;
                text-align: center;
                margin-top: 50px;
                
                @include media_query(){
                    @include rem(18);
                }
                
                &::after{
                    display: block;
                    content: "";
                    width: 100%;
                    height: 6px;
                    background-image: url(../img/icon_arrow.png);
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: auto 6px;
                    margin-top: 20px;
                }
            }
        }
    }
}





.news{
    background-color: #fff;
    
    .news_inner{
        @include media_query(){
            display: flex;
            flex-direction: column;
        }
        
        .btn_archive{
            max-width: 1000px;
            margin: 55px auto 0;
            display: flex;
            justify-content: flex-end;
            
            @include media_query(){
                order: 1;
                justify-content: center;
            }
            
            a{
                display: flex;
                align-items: center;
                font-family: 'Barlow Semi Condensed', sans-serif;
                font-weight: bold;
                @include rem(16);
                color: $font-color;
                letter-spacing: .06em;
                text-decoration: none;
                transition: color .25s;
                        
                &:hover{
                    color: #5E7BB1;
                }
                
                &::after{
                    display: block;
                    content: "";
                    width: 38px;
                    height: 6px;
                    margin-left: 5px;
                    background-image: url(../img/icon_arrow_s.png);
                    background-size: auto 6px;
                    background-repeat: no-repeat;
                    background-position: left center;
                }
            }
        }
    }
}

.news_wrap{
    max-width: 1040px;
    padding: 0 20px;
    margin: 35px auto 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap: 70px 3.5%;
    
    @include media_query(){
        grid-template-columns: auto;                                      
        grid-template-rows: repeat(3, 1fr);
        padding: 0 20px;
        gap: 35px;
        margin: 60px 0 0;
    }
    
    .news_item{
        display: block;
        text-decoration: none;
        
        .news_item_img{
            border-radius: 10px;
            overflow: hidden;
            
            a{
                transition: opacity .25s;
                
                &:hover{
                    opacity: .7;
                }
            }
            
            img{
                width: 100%;
                height: auto;
                vertical-align: bottom;
                aspect-ratio: 31 / 20;
                object-fit: cover;
            }
        }
        
        .news_item_info{
            display: flex;
            align-items: center;
            margin-top: 25px;
            
            @include media_query(){
                margin-top: 15px;
            }
            
            time{
                @include rem(15);
                color: $font-color;
                margin-right: 30px;
            }
            
            ul{
                display: flex;
                align-items: center;
                gap: 10px;
                
                li{
                    list-style: none;
                    text-decoration: none;
                    @include rem(12);
                    white-space: nowrap;
                    color: #fff;
                    background-color: $main-color;
                    padding: 5px 8px;
                    
                    a{
                        color: #fff;
                        text-decoration: none;
                        transition: opacity .25s;
                        
                        &:hover{
                            opacity: .7;
                        }
                    }
                }
            }
        }
        
        h3{
            margin-top: 25px;
            @include rem(16);
            line-height: 1.6;
            color: $font-color;
            
            a{
                color: $font-color;
                text-decoration: none;
                transition: color .25s;
                
                &:hover{
                    color: $main-color;
                }
            }
            
            @include media_query(){
                margin-top: 13px;
            }
        }
    }
}




.whats{
    background-color: #fff;
    padding-top: 180px;
    
    @include media_query(){
        padding-top: 100px;
    }
    
    .whats_inner{
        ul{
            max-width: 1000px;
            margin: 100px auto 0;
            
            @include media_query(){
                padding: 0 20px;
                margin: 60px 0 0;
            }
            
            li{
                list-style: none;
                border-top: solid 1px #A5A4A4;
                
                &:last-child{
                    border-bottom: solid 1px #A5A4A4;
                }
                
                a{
                    opacity: .3;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    text-decoration: none;
                    padding: 30px 50px;
                    
                    @include media_query(){
                        padding: 15px 10px 30px;
                    }
                    
                    background-image: url(../img/icon_arrow.png);
                    background-position: right 50px center;
                    background-repeat: no-repeat;
                    background-size: 88px 6px;
                    
                    transition: background-color .25s;
                    
                    &[href]{
                        opacity: 1;
                        
                        &:hover{
                            background-color: #F2F5F8;
                        }
                    }
                        
                    
                    @include media_query(){
                        background-image: none;
                    }
                    
                    .keyword{
                        font-family: 'Barlow Semi Condensed', sans-serif;
                        font-weight: bold;
                        @include rem(14);
                        color: $main-color;
                        
                        @include media_query(){
                            width: 100%;
                            @include rem(10);
                        }
                    }
                    
                    .keyword_img{
                        width: 106px;
                        height: 81px;
                        margin: 0 40px;
                        text-align: center;
                        
                        @include media_query(){
                            width: 82px;
                            height: 63px;
                            order: 1;
                            margin: 0 0 0 auto;
                        }
                        
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                    
                    h3{
                        @include rem(30);
                        letter-spacing: .2em;
                        color: #3C3C3C;
                        
                        @include media_query(){
                            @include rem(24);
                        }
                    }
                }
                
                &.reasons{
                    .keyword_img{
                        img{
                            max-width: 39px;
                            height: auto;
                            
                            @include media_query(){
                                max-width: 33px;
                            }
                        }
                    }
                }
                
                &.life{
                    .keyword_img{
                        img{
                            max-width: 106px;
                            height: auto;
                            
                            @include media_query(){
                                max-width: 82px;
                            }
                        }
                    }
                }
                
                &.education{
                    .keyword_img{
                        img{
                            max-width: 52px;
                            height: auto;
                            
                            @include media_query(){
                                max-width: 42px;
                            }
                        }
                    }
                }
                
                &.business{
                    .keyword_img{
                        img{
                            max-width: 69px;
                            height: auto;
                            
                            @include media_query(){
                                max-width: 55px;
                            }
                        }
                    }
                }
            }
        }
    }
}