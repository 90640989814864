@use '../base/vars' as *;

.higashihiroshima01{
    img{
        display: block;
        width: 100%;
        height: auto;
        
        & + img{
            margin-top: 35px;
            
            @include media_query(){
                margin-top: 20px;
            }
        }
    }
}






.connection{
    margin-top: 70px;
    
    .connection_title{
        @include rem(15);
        font-weight: normal;
        border-left: solid 3px #000;
        padding-left: .3em;
    }
    
    ul{
        margin-top: .5em;
        
        li{
            @include rem(14);
            list-style: none;
            line-height: 1.8;
            
            a{
                color: #000;
                text-decoration: none;
                
                &:hover{
                    color: #5e7bb1;
                }
            }
        }
        
        & + .connection_title{
            margin-top: 30px;
        }
    }
}









.higashihiroshima02{
    .section_higashihiroshima02_01{
        img{
            width: 100%;
            max-width: 100%;
            height: auto;
            
            & + img{
                margin-top: 50px;
            }
        }
        
        p{
            @include rem(16);
            line-height: 1.8;
            margin: 20px 0;
        }
    }
    
    .section_img_center{
        text-align: center;
        
        .img03{
            max-width: 600px;
            height: auto;
        }
    }
    
    .higashihiroshima02_title{
        text-align: center;
        background-image: url(../img/higashihiroshima/higashihiroshima02/title_bg.png);
        background-repeat: no-repeat;
        background-size: contain;
        padding-bottom: 10px;
        background-position: center top 20px;
        margin-bottom: 30px;
        
        span{
            display: inline-block;
            padding: 10px 20px;
            border-radius: 30px;
            @include rem(24);
            color: #fff;
            background-color: #009D95;
        }
    }
    
    .section_higashihiroshima02_02{
        margin-top: 60px;
        
        .section_higashihiroshima02_02_inner{
            padding: 0 40px;
        }
        
        
        
        p{
            @include rem(16);
            line-height: 1.8;
        }
        
        .human_wrap{
            margin-top: 50px;
            display: grid;
            grid-template-columns: 230px 1fr;
            
            @include media_query(){
                grid-template-columns: 1fr;
            }
            
            & + .human_wrap{
                margin-top: 30px;
            }
            
            .human_img{
                text-align: center;
                
                img{
                    max-width: 150px;
                    height: auto;
                }
                
                p{
                    margin-top: 10px;
                    @include rem(14);
                }
            }
            
            .human_text{
                padding-top: 20px;
                
                h3{
                    @include rem(20);
                    color: #009D95;
                    margin-bottom: 15px;
                    line-height: 1.5;
                }
            }
        }
        
        img{
            width: 100%;
            height: auto;
            
            & + img,
            & + picture{
                display: block;
                margin-top: 50px;
                
                @include media_query(){
                    max-width: 264px;
                    margin: 50px auto 0;
                }
            }
        }
    }
    
    .section_higashihiroshima02_03{
        margin-top: 60px;
        
        .section_higashihiroshima02_03_inner{
            padding: 0 40px;
            
            p{
                @include rem(16);
                line-height: 1.8;
                
                & + img{
                    margin-top: 50px;
                }
            }
            
            img{
                width: 100%;
                height: auto;
            }
            
            .img10{
                display: block;
                margin-top: 50px;
                
                @include media_query(){
                    max-width: 297px;
                    margin: 50px auto 0;
                }
            }
            
            h3{
                @include rem(20);
                color: #009D95;
                margin: 50px 0 30px;
                line-height: 1.5;
            }
        }
    }
}

.section_higashihiroshima02_other{
    margin-top: 30px;
    
    border: dashed 2px #009D95;
    border-radius: 10px;
    padding: 20px;
    
    h3{
        background-color: #009D95;
        color: #fff;
        @include rem(20);
        text-align: center;
        padding: 10px 20px;
        margin-bottom: 20px;
    }
    
    p{
        @include rem(16);
        line-height: 1.8;
    }
    
    .section_higashihiroshima02_other_inner{
        margin-top: 20px;
        background-color: #FFFCDB;
        padding: 20px;
        
        p{
            small{
                @include rem(14);
                
                a{
                    color: #5E7BB1;
                    text-decoration: none;
                }
            }
            
            & +p{
                margin-top: 20px;
            }
        }
    }
}

.section_higashihiroshima02_04{
    padding-top: 30px;
    
    img{
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }
    
    p{
        @include rem(16);
        line-height: 1.8;
    }
}


.section_higashihiroshima02_04_interview{
    margin-top: 50px;
    
    & + .section_higashihiroshima02_04_interview{
        margin-top: 50px;
    }
    
    .section_higashihiroshima02_04_interview_img{
        text-align: center;
        
        img{
            max-width: 500px;
        }
    }
    
    .section_higashihiroshima02_04_interview_img16{
        text-align: center;
        
        img{
            max-width: 590px;
            margin-right: -90px;
            
            @include media_query(){
                margin: 0;
            }
        }
    }
    
    .section_higashihiroshima02_04_interview_img17{
        margin-top: 20px;
        text-align: center;
        
        img{
            max-width: 400px;
        }
    }
    
    h3{
        text-align: center;
        color: #009D95;
        @include rem(20);
        margin: 30px auto 20px;
        max-width: 500px;
        
        small{
            margin-left: 10px;
            @include rem(16);
        }
    }
    
    h4{
        max-width: 500px;
        margin: 0 auto;
        text-align: center;
        color: #009D95;
        border-top: solid 1px #009D95;
        border-bottom: solid 1px #009D95;
        padding: 10px;
        margin-bottom: 30px;
        line-height: 1.5;
    }
}

.section_higashihiroshima02_other2{
    margin-top: 30px;
    border-radius: 10px;
    padding: 40px;
    background-image: url(../img/higashihiroshima/higashihiroshima02/other2_bg.png);
    background-size: cover;
    text-align: center;
    
    @include media_query(){
        padding: 20px;
    }
    
    h3{
        background-color: #009D95;
        color: #fff;
        @include rem(20);
        padding: 10px 20px;
        margin-bottom: 20px;
    }
    
    p{
        @include rem(16);
        line-height: 1.8;
        text-align: left;
    }
    
    img{
        width: 100%;
        max-width: 500px;
        margin-top: 20px;
    }
    
    a{
        color: #333;
    }
}