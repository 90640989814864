@use '../base/vars' as *;

// @include media_query(){}


.higashihiroshima03{

    ul {
        padding: 0 !important;
        background-image: none !important;
    }

    .img{
        width: 100%;
        height: auto;
    }

    .sp_hidden{
        display: block;

        @include media_query(){
            display: none;
        }
    }

    .section_higashihiroshima03_01 {

        .lead{
            margin-top: 50px;
            @include rem(17);
            line-height: 2;
            letter-spacing: 0.2em;
            display: flex;
            text-align: justify;
        }

        @media screen and (max-width: 769px) {
            .lead{
                flex-direction: column-reverse;
            }
        }

        .lead p {
            font-weight: bold;
            text-indent: 1rem;
        }

        .lead figure{
            text-align: center;
            padding: 0 100px;

            @include media_query(){
                padding: 0;
            }
        }

        .lead figure span {
            display: block;
        }

        .lead figure span:nth-child(1){
            @include rem(12);
        }

        .lead figure span:nth-child(2){
            @include rem(16);
            line-height: 1.2;
        }
    }

    .section_higashihiroshima03_04{

        .portal_site {
            width: 100%;
            max-width: 496px;
            margin: 40px auto 0;
        }
    }

    .section_higashihiroshima03_05 {
        padding-bottom: 90px;
        border-bottom: solid 4px #D6D6D6;

        @include media_query(){
            padding-bottom: 60px;
        }

        .answer {
            display: block;
            padding: 28px 30px 150px;
            background-image:  url(../img/higashihiroshima/higashihiroshima03/img08.png);
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: auto;
            
            @include media_query(){
                padding: 28px 30px 300px;
                background-image:  url(../img/higashihiroshima/higashihiroshima03/img08_sp.png);
                background-size: 248px 266px;
            }
        }

        @media screen and (max-width: 769px) {
            .answer{
                background-size: contain;
                padding: 28px 30px 170px;
            }
        }



        .answer .text {
            padding-left: 0;
        }

        .link_button {
            margin: 20px 0 0;
            padding: 16px 0;
            width: 100%;
            max-width: 220px;
        }
    }

    .section_higashihiroshima03_06 {
        padding-top: 90px;

        @include media_query(){
            padding-top: 60px;
        }

        .lead{
            margin-top: 58px
        }

        .lead h3{
            @include rem(21);
            color: #E85382;
            font-weight: bold;
            line-height: 1.5;
        }

        .lead p{
            margin-top: 28px;
            line-height: 2;
            text-indent: 1rem;

            @include media_query(){
                margin-top: 10px;
            }
        }

        .lead img{
            margin-top: 40px;
        }

        .april {
            margin-top: 40px;
        }

        .april_ul {
            background-image: none;
            margin-top: 40px;
        }

        .april_ul li {
            padding-left: 0;
            text-indent: 0
        }

        .april_ul li::before {
            content: none;
        }

        .april_ul li:not(:first-child) {
            margin-top: 26px;
        }

        .april_ul li dt{
            font-weight: bold;
            color: #D93915;
            padding-left: 1.3em;
            text-indent: -1em;

            @include media_query(){
                line-height: 1.5;
            }
        }

        .april_ul li dt::before{
            content: "●";
        }

        .april_ul li dd{
            line-height: 2;
            @include rem(16);
        }

        .voice__icon {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 30px;
        }

        .voice__icon p {
            padding-left: 22px;
        }

        .notice{
            margin-top: 60px;
        }

        .notice p {
            font-weight: 500;
            line-height: 1.7;
        }

        .notice > p {
            margin-top: 30px;
            text-indent: 1rem;
        }

        .notice > img {
            display: block;
            width: 100%;
            max-width: 496px;
            margin: 60px auto 0;
        }

        .notice .voice__icon {
            margin-top: 40px;
        }

        .notice .voice__icon._mt-50 {
            margin-top: 50px;
        }

        .notice .answer h4 {
            @include rem(21);
            font-weight: bold;
            padding-bottom: 10px;
            line-height: 1.7;
        }

        .notice .answer p {
            text-indent: 0;
        }

        .notice .answer ul li {
            @include rem(16);
            font-weight: 500;
            margin-top: 10px;
        }


        .notice .answer ul li::before {
            color: #6355A1;
            @include rem(16);
        }

        .notice .answer {
            display: block;
            padding: 28px 30px 50px;
            background-image:  url(../img/higashihiroshima/higashihiroshima03/img20.png);
            background-position: bottom right;
            background-repeat: no-repeat;
            background-size: auto;
        }

        @media screen and (max-width: 910px) {
            .notice .answer{
                padding: 28px 30px 250px;
            }
        }
        
        .img15{
            display: block;
            margin: 60px auto 0;
            max-width: 496px;
            width: 100%;
        }
    }

    .section_higashihiroshima03_02,
    .section_higashihiroshima03_03,
    .section_higashihiroshima03_04,
    .section_higashihiroshima03_05 {
        margin-top: 60px;
    }

    .answer{
        width: 100%;
        border-radius: 15px;
        color: #6355A1;
        background: #f4f3f8;
        border: 2px solid #6355a1;
        margin: 40px auto 0;
        padding: 28px 30px;
        display: flex;
        line-height: 1.8;

        @include media_query(){
            flex-direction: column;
        }
    }
    .answer .text p{
        text-indent: 1em;
    }
    .answer .text {
        padding-left: 20px;
        text-align: justify;

        @include media_query(){
            margin-top: 20px;
            padding-left: 0;
        }
    }
    .answer .text a{
        text-decoration: none;
        color: #6355A1;

        &:hover{
            opacity: .5;
            transition: opacity .5s ease-out;
            cursor: pointer;
        }
    }
    .answer img {
        width: 88px;
        height: 88px;

        @include media_query(){
            margin: 0 auto;
        }
    }

    .link_button{
        display: block;
        text-decoration: none;
        width: 100%;
        max-width: 318.17px;
        border-radius: 999px;
        background: #6355a1;
        border: solid 1px #6355a1;
        margin: 60px auto 0;
        @include rem(17);
        font-weight: bold;
        text-align: center;
        color: #fff;
        padding: 21px 0;
        letter-spacing: 0.1em;
        position: relative;

        @include media_query(){
            @include rem(14);
        }
    }

    .link_button._lv2{
        max-width: 431.55px;
        background: #e85382;
        border: solid 1px #e85382;
    }

    .link_button::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 30px;
        display: block;
        width: 9px;
        height: 15px;
        content: '';
        background-image: url(../img/higashihiroshima/higashihiroshima03/arrow.png);

        @include media_query(){
            right: 10px;
        }
    }

    .link_button:hover{
        opacity: .5;
        transition: opacity .5s ease-out;
        cursor: pointer;
    }

    .notice_heading{
        @include rem(26);
        font-weight: bold;
        color: #fff;
        width: 720.49px;
        border-radius: 999px;
        background: linear-gradient(#1ab3a3 0.24%, #008176 100%);
        width: 100%;
        display: flex;
        align-items: center;
        padding: 6px;
        line-height: 1.2;

        @include media_query(){
            @include rem(20);
        }
    }

    .notice_heading span {
        display: block;
        padding-left: 10px;
    }

    .dissolution{
        margin-top: 100px;
    }

    .link_button_w{
        color: #fff !important;
    }
}