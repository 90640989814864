@use '../base/vars' as *;

html,
body{
    
}

.ie_alert{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(#4C4948, .2);
    
    .ie_alert_inner{
        max-width: 600px;
        background-color: #fff;
        border: solid 5px #4C4948;
        padding: 80px 40px;
        @include rem(20);
        line-height: 2;
        border-radius: 10px;
        
        .ie_logo{
            text-align: center;
            margin-bottom: 30px;
        }
        
        .btn_edge{
            display: flex;
            justify-content: center;
            
            a{
                display: block;
                @include rem(20);
                font-weight: bold;
                color: #fff;
                background-color: #4a9bff;
                text-decoration: none;
                padding: .8em 1em;
                margin-top: 30px;
                border-radius: 5px;
            }
        }
    }
}

.wrap{
    &.is_ie{
        display: none !important;
        visibility: hidden !important;
        opacity: 0 !important;
    }
}

.header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    
    .drawer_open{
        display: none;
        
        @include media_query(750){
            display: block;
            margin-left: auto;
        }
    }
    
    &.inview .header_inner .logo a img{
        max-width: 100px;
        
        @include media_query(){
            max-width: 60px;
        }
    }
    
    .header_inner{
        margin: 0 auto;
        display: flex;
        padding: 20px;
        background-color: #fff;
        
        @include media_query(){
            background-color: transparent !important;
            padding: 20px;
        }
        
        .logo{
            margin-right: auto;
            
            a{
                transition: opacity .1s;
                
                img{
                    width: 100%;
                    max-width: 143px;
                    height: auto;
                    
                    transition: max-width .25s;
                    
                    @include media_query(){
                        max-width: 89px;
                    }
                }
            
                &:hover{
                    opacity: .7;
                }
            }
        }
        
        .globalnav{
            margin-left: auto;
            
            @include media_query(750){
                position: absolute;
                left: 0;
                top: 0;
                z-index: 9999;
                width: 100vw;
                height: 100vh;
                background-color: #F2F5F8;
                
                visibility: hidden;
                opacity: 0;
                
                transition: visibility .4s, opacity .4s;
                
            }
            
            .globalnav_header{
                display: none;
                
                @include media_query(750){
                    display: flex;
                    align-items: center;
                    height: 65px;
                    padding: 0 20px;
                }
                
                .nav_logo{
                    a{
                        img{
                            width: 100%;
                            max-width: 89px;
                            height: auto;
                        }
                    }
                }
                
                .drawer_close{
                    margin-left: auto;
                }
            }
            
            &>ul{
                display: flex;
                gap: 45px;
                
                @include media_query(750){
                    display: block;
                    flex-direction: column;
                    gap: 0;
                    max-width: 250px;
                    margin: 30px auto;
                }
                
                li{
                    & + li{
                        @include media_query(750){
                            margin-top: 30px;
                        }
                    }
                    
                    a[href]{
                        opacity: 1;
                        text-decoration: none;
                        color: $font-color;
                        font-weight: bold;
                        
                        transition: color .25s;
                        
                        @include media_query(750){
                            @include rem(22);
                        }
                        
                        &:hover{
                            color: #5E7BB1;
                        }
                    }
                    
                    a{
                        opacity: .3;
                        text-decoration: none;
                        color: $font-color;
                        font-weight: bold;
                        
                        transition: color .25s;
                        
                        @include media_query(750){
                            @include rem(22);
                        }
                    }
                }
            }
            
            .sp_lang{
                display: none;
                max-width: 250px;
                margin: 0 auto;
                
                @include media_query(750){
                    display: block;
                }
                
                h2{
                    display: flex;
                    align-items: center;
                    
                    span{
                        font-family: 'Barlow Semi Condensed', sans-serif;
                        font-weight: 600;
                        @include rem(13);
                        margin-left: 5px;
                    }
                }
                
                ul{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 20px 3%;
                    margin: 20px 0 0;
                    
                    @include media_query(750){
                        display: flex;
                        flex-wrap: wrap;
                        margin-left: -3%;
                        gap: 0;
                    }
                    
                    li{
                        @include media_query(750){
                            width: calc(33.333% - 3%);
                            margin-left: 3%;
                            margin-bottom: 15px;
                        }
                        
                        @include rem(15);
                    }
                }
            }
            
            .sp_sns{
                display: none;
                max-width: 250px;
                margin: 35px auto;
                
                @include media_query(750){
                    display: block;
                    margin-top: 10px;
                }
                
                h3{
                    font-family: 'Barlow Semi Condensed', sans-serif;
                    font-weight: 600;
                    @include rem(13);
                    margin-bottom: 20px;
                }
                
                dl{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    
                    img{
                        width: 100%;
                        max-width: 55px;
                        height: auto;
                    }
                }
            }
            
            &.open{
                visibility: visible;
                opacity: 1;
            }
        }
    }
}





.main{
    
}




.section_title{
    span{
        display: block;
        font-family: 'Barlow Semi Condensed', sans-serif;
        font-weight: 600;
        color: #fff;
        @include rem(72);
        text-shadow:1px 1px 0 $font-color,-1px 1px 0 $font-color,1px -1px 0 $font-color,-1px -1px 0 $font-color;
        letter-spacing: .03em;
        margin-bottom: 15px;
        
        @include media_query(){
            @include rem(40);
        }
    }
    
    text-align: center;
    @include rem(19);
    font-weight: bold;
    letter-spacing: .05em;
    color: $font-color;
    
    @include media_query(){
        @include rem(15);
    }
}

.section_title_sub{
    @include rem(48);
    margin-top: 50px;
}





.footer{
    padding-top: 150px;
    background-color: #fff;
    
    @include media_query(980){
        padding-top: 100px;
    }
    
    &::before{
        display: block;
        content: "";
        width: 100%;
        height: 49px;
        background-image: url(../img/pickup_bg_top.png);
        background-size: auto 49px;
        background-color: #fff;
    }
    
    .footer_inner{
        background-color: #F2F5F8;
    }
    
    .footer_main{
        max-width: 1040px;
        padding: 60px 20px 50px;
        margin: 0 auto;
    }
    
    .footer_top{
        display: flex;
        align-items: center;
        
        @include media_query(980){
            flex-direction: column;
            align-items: flex-start;
        }
        
        .footer_logo{
            display: flex;
            align-items: center;
            
            img{
                width: 100%;
                max-width: 117px;
                height: auto;
            }
            
            p{
                @include rem(11);
                line-height: 1.9;
                margin-left: 15px;
                letter-spacing: 0.235em;
                color: $font-color;
            }
        }
        
        .footernav{
            margin-left: auto;
            
            @include media_query(980){
                margin: 45px 0 0;
                
                display: flex;
            }
            
            &>ul{
                display: flex;
                gap: 45px;
                
                @include media_query(980){
                    flex-direction: column;
                    gap: 20px;
                }
                
                li{
                    a[href]{
                        opacity: 1;
                        text-decoration: none;
                        color: $font-color;
                        font-weight: bold;
                        
                        transition: color .25s;
                        
                        @include media_query(980){
                            @include rem(22);
                        }
                        
                        &:hover{
                            color: #5E7BB1;
                        }
                    }
                    
                    a{
                        opacity: .3;
                        text-decoration: none;
                        color: $font-color;
                        font-weight: bold;
                        
                        transition: color .25s;
                        
                        @include media_query(980){
                            @include rem(22);
                        }
                    }
                }
            }
            
            .sp_lang{
                display: none;
                max-width: 250px;
                margin: 0 0 0 50px;
                
                @include media_query(980){
                    display: block;
                }
                
                h2{
                    display: flex;
                    align-items: center;
                    
                    span{
                        font-family: 'Barlow Semi Condensed', sans-serif;
                        font-weight: 600;
                        @include rem(13);
                        margin-left: 5px;
                    }
                }
                
                ul{
                    margin: 20px 0 0;
                    
                    li{
                        @include rem(15);
                        
                        & + li{
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }
    
    .footer_contents{
        display: flex;
        
        @include media_query(980){
            flex-direction: column;
            margin-top: 60px;
        }
        
        .info{
            @include rem(13);
            line-height: 1.6;
            padding-top: 55px;
            
            @include media_query(980){
                padding: 0;
                margin-top: 70px;
                order: 1;
            }
            
            a{
                color: $font-color;
                transition: color .25s;
                text-decoration: none;
                        
                &:hover{
                    color: #5E7BB1;
                }
            }
        }
        
        .sns{
            margin-left: auto;
            
            @include media_query(980){
                margin: 0;
            }
            
            dl{
                display: flex;
                align-items: center;
                gap: 20px;
                color: $font-color;
                
                @include media_query(980){
                    flex-wrap: wrap;
                }
                
                dt{
                    font-family: 'Barlow Semi Condensed', sans-serif;
                    font-weight: 600;
                    @include rem(16);
                    
                    @include media_query(980){
                        width: 100%;
                    }
                }
                
                dd{
                    a{
                        transition: opacity .25s;
                        
                        &:hover{
                            opacity: .7;
                        }
                    }
                }
            }
        }
    }
    
    .footer_bottom{
        display: flex;
        align-items: center;
        margin-top: 35px;
        padding-top: 45px;
        border-top: solid 1px #C9C8C8;
        
        @include media_query(980){
            margin-top: 30px;
            padding-top: 35px;
        }
        
        @include media_query(980){
            flex-direction: column;
            align-items: flex-start;
        }
        
        .hh_logo{
            img{
                width: 100%;
                max-width: 122px;
                height: auto;
            }
        }
        
        .privacy{
            margin-left: auto;
            
            @include media_query(980){
                margin: 24px 0 0;
            }
            
            & + .privacy{
                margin-left: 20px;
            }
            
            a{
                text-decoration: none;
                @include rem(13);
                color: $font-color;
                transition: opacity .25s;
                        
                &:hover{
                    opacity: .7;
                }
            }
        }
        
        .copy{
            margin-left: 44px;
            @include rem(11);
            color: $font-color;
            
            @include media_query(980){
                margin: 16px 0 0;
            }
        }
    }
}





.notfound{
    padding: 200px 50px;
    @include rem(40);
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 600;
    text-align: center;
    letter-spacing: .2em;
}





.pagenation{
    margin-top: 150px;
    display: flex;
    justify-content: center;
    gap: 5px;
    font-family: 'Barlow Semi Condensed', sans-serif;
    font-weight: 600;
    
    @include media_query(){
        margin-top: 80px;
    }
    
    li{
        list-style: none;
        
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 36px;
            height: 36px;
            color: #4C4948;
            @include rem(17);
            text-decoration: none;
            opacity: .5;
            border-bottom: solid 2px transparent;
            
            transition: opacity .25s;
            
            &:hover{
                opacity: 1;
            }
        }
        
        &.active{
            span{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                color: #4C4948;
                border-bottom: solid 2px #4C4948;
            }
        }
    }
    
    .prev{
        margin-right: 70px;
        
        @include media_query(){
            margin: 0;
        }
        
        a{
            justify-content: flex-end;
            width: 82px;
            background-image: url(../img/icon_arrow_paged_l.png);
            background-position: left center;
            background-size: 39px 6px;
            background-repeat: no-repeat;
            @include rem(14);
        }
    }
    
    .next{
        margin-left: 70px;
        
        @include media_query(){
            margin: 0;
        }
        
        a{
            justify-content: flex-start;
            width: 82px;
            background-image: url(../img/icon_arrow_paged_r.png);
            background-position: right center;
            background-size: 39px 6px;
            background-repeat: no-repeat;
            @include rem(14);
        }
    }
}



.lang{
    cursor: pointer;
    position: relative;
    
    @include media_query(980){
        display: none;
    }
}

.inner_ul{
    visibility: hidden;
    opacity: 0;
    width: 180px;
    position: absolute;
    right: 5px;
    top: calc(100% + 15px);
    
    border: solid 1px #5E7BB1;
    
    transition: visibility .25s, opacity .25s;
    
    &.show{
        visibility: visible;
        opacity: 1;
    }
    
    &::before{
        position: absolute;
        right: -8px;
        bottom: calc(100% - 2px);
        width: 0;
        height: 0;
        display: block;
        content: "";
        border-right: 11px solid transparent;
        border-bottom: 11px solid #5E7BB1;
        border-left: 11px solid transparent;
        transform: rotate(135deg);
    }
    
    li{
        padding: 10px 28px;
        background-color: #fff;
        
        &:first-child{
            font-family: 'Barlow Semi Condensed', sans-serif;
            font-weight: 600;
            color: #fff;
            background-color: #5E7BB1;
            @include rem(17);
            letter-spacing: .07em;
        }
        
        &:not(:first-child){
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: color .25s;
            
            &:hover{
                color: $main-color;
            }
            
            &::after{
                margin-left: auto;
                display: block;
                content: "";
                border-top: solid 1px #5E7BB1;
                border-right: solid 1px #5E7BB1;
                width: 7px;
                height: 7px;
                transform: rotate(45deg);
            }
        }
    }
}




.cookie{
    max-width: 940px;
    padding: 200px 20px 0;
    margin: 0 auto;
    
    @include media_query(){
        padding: 150px 20px 0;
    }
    
    h1{
        @include rem(30);
        text-align: center;
        margin-bottom: 120px;
        
        @include media_query(){
            @include rem(24);
            margin-bottom: 70px;
        }
        
        &::after{
            content: "";
            display: block;
            width: 100px;
            height: 1px;
            margin: 30px auto 0;
            background-color: $font-color;
        }
    }
    
    .indent{
        p{
            text-indent: -2.5em;
            padding-left: 2em;
        }
    }
    
    p{
        @include rem(18);
        line-height: 1.7;
        
        @include media_query(){
            @include rem(16);
        }
        
        & + p{
            margin-top: 1em;
        }
    }
    
    h2{
        @include rem(26);
        margin: 100px 0 35px;
        
        @include media_query(){
            @include rem(21);
            margin: 70px 0 30px;
        }
    }
    
    ul{
        padding-left: 3.5em;
        margin-top: 35px;
        line-height: 1.8;
        
        @include media_query(){
            margin-top: 30px;
        }
        
        li{
            @include rem(18);
            
            @include media_query(){
                @include rem(16);
            }
            
            a{
                color: $font-color;
            }
        }
    }
}









.img_pc{
    display: inline-block !important;
    
    @include media_query(){
        display: none !important;
    }
}

.img_sp{
    display: none !important;
    
    @include media_query(){
        display: inline-block !important;
    }
}