@use '../base/vars' as *;

// @include media_query(){}


.higashihiroshima04{
    line-height: 1.7;


    a {
        display: block;
        text-decoration: none;
        color: #000;

        &:hover{
            opacity: .5;
            transition: opacity .5s ease-out;
            cursor: pointer;
        }
    }

    ul {
        padding: 0 !important;
        background-image: none !important;
    }

    .img{
        width: 100%;
        height: auto;
        vertical-align: bottom;
    }

    .sp_hidden{
        display: block;

        @include media_query(){
            display: none;
        }
    }

    .section_higashihiroshima04_01 {

        .lead {
            margin-top: 50px;
        }

        .lead p:nth-child(1) {
            @include rem(17);
            text-indent: 1rem;
            font-weight: bold;
            line-height: 2;
        }

        .lead p:nth-child(2){
            @include rem(16);
            font-weight: 500;
        }

        .advice{
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img03.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 60px auto 0;
            padding: 24px;
            display: flex;
            border-radius: 20px;

            @include media_query(){
                display: block;
            }
        }

        .advice_text {
            padding-right: 40px;

            @include media_query(){
                padding-right: 0;
            }
        }

        .advice_text h3{
            @include rem(24);
            font-weight: bold;
            color: #F07800;
        }

        .advice_text dl {
            margin-top: 20px;
        }

        .advice_text dt{
            @include rem(18);
            font-weight: bold;
        }

        .advice_text dt span {
            @include rem(14);
        }

        .advice_text dd {
            @include rem(15);
            font-weight: 500;
            margin-top: 10px;
        }

        .advice img {
            max-width: 182px;
            margin: 0 auto;
            display: block;
        }

        .family {
            @include rem(15);
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0;

            @include media_query(){
                margin-top: 40px;
                display: block;
            }
        }

        .family_text {
            padding-right: 10%;
        }

        .family_text h3 {
            border-left: solid 4px #F07800;
            padding-left: 10px;
            line-height: 2;
        }

        .family_text p{
            position: relative;
            width: 100%;
            max-width: 300.58px;
            border-radius: 10px;
            background: #f07800;
            color: #fff;
            margin-top: 50px;
            padding: 20px;

            @include media_query(){
                margin-top: 30px;
            }
        }

        .family_text p::after{
            position: absolute;
            top: 50px;
            bottom: auto;
            right: -30px;
            content: '';
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 40px 0 0;
            border-color: #f07800 transparent transparent transparent;
            transform: rotate(-15deg);

            @include media_query(){
                top: auto;
                bottom: -20px;
                right: 100px;
                transform: rotate(65deg);
            }
        }

        .family img {
            max-width: 287px;
            display: block;
            margin: 0;

            @include media_query(){
                margin: 0 auto;
            }
        }
    } // section_higashihiroshima04_01

    .section_higashihiroshima04_02 {
        padding-top: 130px;

        h2 {
            width: 100%;
            max-width: 232px;
            margin: 0 auto;
        }

        .contents_lists {
            margin: 30px auto 0;
            width: 100%;
            max-width: 620px;
            border-top: solid 2px #E0E0E0;
        }

        .contents_lists li {
            padding-left: 0 !important;
            text-indent: 0 !important;
        }

        .contents_lists li::before{
            content: none !important;
        }

        .contents_lists dl {
            display: flex;
            align-items: center;
            border-bottom: solid 2px #E0E0E0;
            padding:  20px;
        }

        .contents_lists dt {
            width: 100%;
            max-width: 59px;
        }

        .contents_lists dd {
            padding-left: 20px;
        }

        .contents_lists li:nth-child(1) dd {
            width: 100%;
            max-width: 373px;
        }

        .contents_lists li:nth-child(2) dd {
            width: 100%;
            max-width: 339px;
        }

        .contents_lists li:nth-child(3) dd {
            width: 100%;
            max-width: 454px;
        }

        .contents_lists li:nth-child(4) dd a {
            display: block;
            text-decoration: none;
            @include rem(21);
            font-weight: bold;
            color: #0099D9;
            letter-spacing: 0.1em;
        }
    } // section_higashihiroshima04_02


    .section_higashihiroshima04_03 {
        margin-top: 190px;

        .lead {
            display: flex;
            align-items: center;
            @include rem(16);
            font-weight: 500;
            line-height: 2;
            margin-top: 30px;

            @include media_query(){
                display: block;
            }
        }

        .lead img {
            padding-right: 8%;
            margin: 0;
            display: block;

            @include media_query(){
                margin: 0 auto;
            }
        }

        .choices {
            margin-top: 50px;
            border: solid 2px #0099D9;
            background-color: #FFFAC5;
        }

        .choices h3 {
            color: #fff;
            @include rem(21);
            font-weight: bold;
            letter-spacing: 0.1em;
            background-color: #0099D9;
            padding: 10px 24px;
        }

        .choices_lists {
            padding: 22px;
            @include rem(16);
            font-weight: 500;
        }

        .choices_lists dl {
            margin-top: 20px;
        }

        .choices_lists p {
            text-indent: 1rem;
            line-height: 2;
        }

        .choices_lists h4 {
            @include rem(17);
            font-weight: bold;
            color: #0099D9;
        }

        .choices_lists ul {
            margin-top: 0;
        }

        .choices_lists ul li{
            @include rem(16);
        }

        .choices_lists ul li::before {
            color: #0099D9;
            @include rem(16);
        }

        .map{
            margin-top: 60px;
        }

        .map .lead {
            @include rem(16);
            font-weight: 500;
            margin-top: 20px;
        }

        .map_lists {
            margin-top: 30px;
        }

        .map_lists li div {
            display: flex;
        }
        @media screen and (max-width: 769px) {
            .map_lists li div{
                flex-direction: column-reverse;
            }
        }

        .map_lists li:nth-child(1){
            border-bottom: solid 1px #989898;
            padding-bottom: 30px;
        }

        .map_lists li:nth-child(2) {
            padding-top: 50px;
        }

        .map_lists li {
            padding-left: 0 !important;
            text-indent: 0 !important;
        }

        .map_lists li::before{
            content: none !important;
        }

        .lead p {
            font-weight: bold;
        }

        .map_lists figure {
            margin-top: 0;
            padding-right: 8%;
        }
        @media screen and (max-width: 769px) {
            .map_lists figure{
                margin-top: 20px;
                padding-right: 0;
            }
        }

        .map_lists figure img {
            display: block;
            margin: 10px 0 0;
        }
        @media screen and (max-width: 769px) {
            .map_lists figure img{
                margin: 10px auto 0;
            }
        }


        .map_lists figcaption {
            @include rem(18);
            font-weight: bold;
            color: #0099D9;
            text-align: center;
        }

        .map_lists dt {
            @include rem(18);
            font-weight: bold;

            @include media_query(){
                @include rem(17);
            }
        }

        .map_lists dd {
            margin-top: 10px;
        }

        .map_lists dd p{
            @include rem(16);
            font-weight: 500;
            text-indent: 1rem;
        }

        .map_lists .notes {
            @include rem(12);
            font-weight: 500;
            letter-spacing: 0.1em;
        }

        .opening{
            position: relative;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img03.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 60px auto 0;
            padding: 30px;
            border-radius: 20px;
        }

        .opening h3 {
            @include rem(16);
            font-weight: bold;
            color: #F07800;
        }

        .opening a {
            width: 100%;
            max-width: 440px;
            margin: 30px auto 0;
        }

        .opening .face {
            position: absolute;
            top: auto;
            bottom: -20px;
            right: -30px;
            width: 116px;
        }

        @media screen and (max-width: 769px) {
            .opening .face{
                top: -50px;
                bottom: auto;
                right: -20px;
            }
        }
    } // section_higashihiroshima04_03


    .section_higashihiroshima04_04 {
        margin-top: 200px;

        @include media_query(){
            margin-top: 100px;
        }

        .lead {
            margin-top: 50px
        }

        .lead h3 {
            @include rem(21);
            font-weight: bold;
            color: #0099D9;
        }

        .lead p{
            @include rem(16);
            font-weight: 500;
            text-indent: 1rem;
            line-height: 2;
            margin-top: 10px
        }


        .choices02 {
            margin-top: 50px;
            padding-bottom: 40px;
            border: solid 2px #0099D9;
            background-color: #FFFAC5;
        }

        .choices02 h3 {
            color: #fff;
            @include rem(24);
            font-weight: bold;
            letter-spacing: 0.1em;
            background-color: #0099D9;
            padding: 10px 24px;
        }

        .choices02 .button_lv1 {
            margin: 0 auto;
        }

        .choices_lists02 {
            padding: 22px 22px 40px;
            @include rem(16);
            font-weight: 500;
            display: flex;
        }
        @media screen and (max-width: 769px) {
            .choices_lists02{
                display: block;
            }
        }

        .choices_lists02 p {
            @include rem(16);
            font-weight: 500;
            text-indent: 1rem;
            line-height: 2;
            width: 50%;
            padding-right: 5%;
        }
        @media screen and (max-width: 769px) {
            .choices_lists02 p {
                width: 100%;
            }
        }

        .choices_lists02 img {
            width: 50%;
            margin: 0 auto;
        }
        @media screen and (max-width: 769px) {
            .choices_lists02 img{
                width: 100%;
                margin: 20px auto 0;
            }
        }

        .vigilance{
            margin-top: 100px
        }

        .vigilance h4 {
            position: relative;
            display: inline-block;
            margin-top: 40px;
            @include rem(18);
            font-weight: bold;
            color: #0099D9;

            @include media_query() {
                margin-top: 100px;
            }
        }

        .vigilance h4::after{
            content: '';
            display: block;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img23.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            width: 69px;
            height: 69px;
            position: absolute;
            top: 50%;
            right: -66px;
            transform: translateY(-50%);

            @include media_query() {
                top: -30px;
                right: -40px;
            }
        }

        .vigilance .lead_dl {
            margin-top: 20px;
        }

        .vigilance .lead_dl dt {
            @include rem(21);
            font-weight: bold;
        }

        .vigilance .lead_dl dd {
            @include rem(16);
            font-weight: 500;
            text-indent: 1rem;
            line-height: 2;
            margin-top: 10px
        }

        .example {
            margin-top: 50px;
        }

        .example h3 {
            @include rem(16);
            font-weight: bold;
            padding: 10px 0;
            border-top: solid 2px #323232;
            border-bottom: solid 2px #323232;
        }

        .example_lists {
            width: 100%;
            max-width: 800px;
            margin: 30px auto 0;
        }

        .example_lists ul {
            margin-left: -5%;
            display: flex;

            @include media_query(){
                display: block;
                margin-left: 0;
            }
        }

        .example_lists ul li {
            padding-top: 0;
            padding-left: 5%;
            text-indent: 0 !important;
            width: 33.333%;

            @include media_query(){
                padding-top: 50px;
                padding-left: 0 !important;
                width: 100%;
            }
        }

        .example_lists ul li::before{
            content: none !important;
        }

        .example_lists ul li img {
            display: block;
            margin: 0 auto;
        }

        .example_lists ul li:nth-child(1) img{
            max-width: 195px;
        }

        .example_lists ul li:nth-child(2) img{
            max-width: 202px;
        }

        .example_lists ul li:nth-child(3) img{
            max-width: 194px;
        }

        .register{
            margin-top: 50px;
            padding-bottom: 50px;
        }

        .register > p {
            margin-top: 30px;
            @include rem(16);
            font-weight: 500;
            text-indent: 1rem;
            line-height: 2;
        }

        .register_wrap {
            padding: 40px;
            background-color: #FFFAC5;

            @include media_query(){
                padding: 20px;
            }
        }

        .register_wrap p{
            @include rem(16);
            font-weight: 500;
            line-height: 2;
        }

        .register_wrap .btn {
            position: relative;
            width: 100%;
            max-width: 388px;
            border-radius: 999px;
            background: #F07800;
            @include rem(15);
            font-weight: bold;
            color: #fff;
            text-align: center;
            margin: 0 auto;
            padding: 20px;
            line-height: 1.2;
        }

        .register_wrap .btn::before{
            content: '';
            display: block;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img28.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            width: 8px;
            height: 14px;
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
        }

        .register_wrap div .btn:nth-child(1){
            margin-top: 30px;
        }

        .register_wrap div .btn:nth-child(2){
            margin-top: 20px;
        }

        .step .notes01,
        .step .notes02 {
            @include rem(12);
            font-weight: 500;
            margin-top: 20px;
        }

        .step .notes01{
            text-align: center;
        }
        .step ._table{
            margin-top: 10px;
        }
    } // section_higashihiroshima04_04
    
    .img30{
        @include media_query(){
            width: 100%;
            overflow: scroll;
            
            img{
                width: 650px;
            }
        }
    }

    .section_higashihiroshima04_05{
        margin-top: 200px;

        @include media_query(){
            margin-top: 100px;
        }

        .lead {
            margin-top: 58px;
        }

        .lead h3{
            @include rem(21);
            font-weight: bold;
            color: #0099D9;
        }

        .lead > p {
            @include rem(16);
            font-weight: 500;
            text-indent: 1rem;
            margin-top: 16px;
        }

        .lead .advice_lv1 {
            margin-top: 40px;
        }

        .protect {
            margin-top: 80px;
        }

        .protect > p {
            @include rem(16);
            font-weight: 500;
            text-indent: 1rem;
            margin-top: 30px;
        }

        .protect .advice_lv1 {
            margin-top: 50px;
        }

        .check {
            position: relative;
            width: 100%;
            max-width: 780px;
            margin: 50px auto 0;
            background-color: #EAF2F8;
            padding: 32px 40px 0;
            box-shadow: 5px 5px 0px 0px #EAEAEA;

            @include media_query(){
                padding: 32px 16px 0;
            }
        }

        .check::before{
            content: '';
            display: block;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img33.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            width: 158px;
            height: 82px;
            position: absolute;
            top: 40px;
            left: 50px;
        }

        @media screen and (max-width: 769px) {
            .check::before{
                top: 30px;
                left: 10px;
            }
        }
        @media screen and (max-width: 580px) {
            .check::before{
                width: 62px;
                height: 32.27px;
                top: 80px;
                left: 10px;
            }
        }

        .check::after{
            content: '';
            display: block;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img32.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            width: 158px;
            height: 82px;
            position: absolute;
            top: 40px;
            right: 50px;
        }

        @media screen and (max-width: 769px) {
            .check::after{
                top: 30px;
                right: 10px;
            }
        }
        @media screen and (max-width: 580px) {
            .check::after{
                width: 72.36px;
                height: 37.62px;
                top: 80px;
                right: 10px;
            }
        }

        .check h3 {
            text-align: center;
            width: 100%;
            max-width: 520px;
            margin: 0 auto;
            padding-bottom: 30px;
            border-bottom: solid 2px #3690C0;
            color: #3690C0;
        }

        .check h3 span {
            display: block;
            letter-spacing: 0.1em;
        }

        .check h3 span:nth-child(1){
            @include rem(21);
            font-weight: bold;

            @include media_query(){
                @include rem(14);
            }
        }

        .check h3 span:nth-child(2){
            @include rem(32);
            font-weight: bold;
            line-height: 1;
            margin-top: 8px;

            @include media_query(){
                @include rem(26);
            }
        }

        .check_lists {
            margin-top: 40px;
            display: flex;

            @include media_query(){
                display: block;
            }
        }

        .check_lists ul {
            width: 50%;
            margin-left: -14px;

            @include media_query(){
                width: 100%;
                margin-top: 0;
                margin-left: 0;
            }
        }

        .check_lists ul li {
            padding-top: 14px;
            padding-left: 14px;
            text-indent: 0 !important;
            @include rem(16);
            font-weight: 500;
            display: flex;

            @include media_query(){
                padding-left: 0;
            }
        }

        .check_lists ul li::before{
            content: none !important;
        }

        .check_lists ul .square{
            display: block;
            width: 17px;
            height: 17px;
            background-color: #fff;
            border: solid 1px #3690C0;
            flex-shrink: 0;
        }

        .check_lists ul li p {
            padding-left: 10px;
            line-height: 1.2;
        }

        .check_lists ul li p span {
            @include rem(15);
        }

        .point {
            margin-top: 40px;
            display: flex;
            justify-content: space-between;

            @media screen and (max-width: 769px) {
                display: block;
            }
        }

        .point_text {
            width: 200px;
            height: 200px;
            border-radius: 999px;
            text-align: center;
            margin: 0 auto;
            padding: 20px;
            background-color: #3690C0;
            flex-shrink: 0;
        }

        .point_text p {
            @include rem(16);
            font-weight: bold;
            color: #fff;
            margin-top: 6px
        }

        .point > img {
            max-width: 427px;
            display: block;
            margin: 0 0 0 auto;

            @include media_query(){
                margin: 20px 0 0 auto;
            }
        }


        .pet{
            margin-top: 50px
        }

        .pet .bottom_text {
            display: flex;
            align-items: center;
            margin-top: 10px;
        }

        .pet .bottom_text > p {
            position: relative;
            @include rem(18);
            font-weight: bold;
            color: #F07800;
            letter-spacing: 0.1em;
            padding-left: 10px;
        }

        .pet .bottom_text > img {
            width: 69px;
            height: 69px;
        }

        .pet_wrap{
            display: flex;

            @include media_query(){
                display: block;
            }
        }

        .pet_wrap .item01{
            width: 50%;

            @include media_query(){
                width: 100%;
            }
        }

        .pet_wrap .item02 {
            width: 100%;
            max-width: 400px;
            margin: 0 auto;
        }

        .pet_wrap .item01{
            padding-right: 3%;
        }

        .pet_wrap .item01 h3 {
            @include rem(21);
            font-weight: bold;
            color: #0099D9;
        }

        .pet_wrap .item01 p {
            @include rem(16);
            font-weight: 500;
            text-indent: 1rem;
        }

        .pet_wrap .item02 {
            position: relative;
            border: solid 10px #FFF000;
            padding: 30px 10px;
            margin-top: 0;
            border-radius: 10px;

            @include media_query(){
                margin-top: 30px;
            }
        }

        .pet_wrap .item02::before{
            content: '';
            display: block;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img36.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            width: 82px;
            height: 24px;
            position: absolute;
            top: -15px;
            left: 50%;
            transform: translateX(-50%);
        }

        .pet_wrap .item02::after{
            content: '';
            display: block;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img37.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            width: 112px;
            height: 73px;
            position: absolute;
            bottom: 10px;
            right: 30px;
        }

        .pet_wrap .item02 h3 {
            @include rem(18);
            font-weight: bold;
            color: #fff;
            background-color: #F08300;
            border-radius: 999px;
            text-align: center;
            padding: 2px 0;
        }

        .pet_wrap .item02 ul {
            margin-top: 10px;
        }

        .pet_wrap .item02 ul li {
            padding-left: 0 !important;
            text-indent: 0 !important;
            display: flex;
            align-items: center;
        }

        .pet_wrap .item02 ul li::before{
            content: none !important;
        }

        .pet_wrap .item02 .square{
            display: block;
            width: 12px;
            height: 12px;
            border: solid 1px #000;
            background-color: #fff;
        }

        .pet_wrap .item02 p{
            @include rem(16);
            font-weight: 500;
            padding-left: 6px
        }

        .pet_wrap .item02 p span{
            @include rem(14);
            font-weight: 500;
        }

        .box_cardList {
            margin-top: 0;

            @include media_query(){
                margin-top: 80px;
            }
        }

        .box_cardList .box_card:nth-child(1) h3 {
            position: relative;
            padding-left: 100px;
        }

        .box_cardList .box_card:nth-child(1) h3::before {
            content: '';
            display: block;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img38.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            width: 80px;
            height: 82px;
            position: absolute;
            top: -20px;
            left: 10px;
        }


        .box_card {
            position: relative;
            margin-top: 50px;
            border: solid 2px #0099D9;
            background-color: #FFFAC5;
        }

        .box_card h3 {
            color: #fff;
            @include rem(21);
            font-weight: bold;
            letter-spacing: 0.1em;
            background-color: #0099D9;
            padding: 10px 24px;
        }

        .box_card-wrap {
            padding: 22px;
            @include rem(16);
            font-weight: 500;

            @include media_query(){
                padding: 22px 22px 22px;
            }
        }
        
        .box_card-wrap_l{
            @include media_query(){
                padding: 22px 22px 170px;
            }
        }

        .box_card-wrap .lead {
            margin-top: 0;
            text-indent: 1rem;
            @include rem(16);
            font-weight: 500;
        }

        .box_card-wrap ._dl {
            display: flex;
            align-items: flex-start;
            margin-top: 30px;

            @include media_query(){
                display: block;
            }
        }

        .box_card-wrap ._dl dt {
            position: relative;
            color: #fff;
            background-color: #0099D9;
            text-align: center;
            padding: 2px;
            width: 100%;
            max-width: 160px;
            font-weight: bold;
        }

        .box_card-wrap ._dl dt::after{
            content: '';
            display: block;
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 12px 31px 0;
            border-color: transparent #fffac5 transparent transparent;
            position: absolute;
            top: 0;
            right: 0;

            @include media_query(){
                border-width: 0 12px 36px 0;
                top: -1px;
                right: -1px;
            }
        }

        .box_card-wrap ._dl dd {
            padding-top: 0;
            padding-left: 40px;
            flex: 1;

            @include media_query(){
                padding-top: 10px;
                padding-left: 0;
            }
        }

        .box_card-wrap ._dl dd .button_lv1:nth-child(2) {
            margin-top: 20px;
        }

        .box_cardList .box_card:nth-child(1) ._dl dd ._link{
            display: inline-block;
            // pointer-events: none;
        }

        .box_cardList .box_card:nth-child(2) .box_card-wrap {
            display: flex;

            @include media_query() {
                display: block;
            }
        }

        .box_cardList .box_card:nth-child(2) .box_card-wrap > div {
            padding-right: 5%;
        }

        .box_cardList .box_card:nth-child(2) .box_card-wrap > img {
            max-width: 292px;

            @include media_query() {
                margin-top: 20px;
            }
        }

        .box_cardList .box_card:nth-child(2) .box_card-wrap .button_lv1 {
            margin-top: 40px;

            @include media_query() {
                margin-top: 20px;
            }
        }

        .box_cardList .box_card:nth-child(3)::before{
            content: '';
            display: block;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img41.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            width: 79px;
            height: 131px;
            position: absolute;
            bottom: 20px;
            right: 50px;
        }

        .box_cardList .box_card:nth-child(3) .box_card-wrap ul li {
            @include rem(16);
            font-weight: bold;
        }

        .box_cardList .box_card:nth-child(3) .box_card-wrap ul li::before {
            color: #0099D9;
            @include rem(16);
        }

        .box_cardList .box_card:nth-child(3) .box_card-wrap ul li:nth-child(1) {
            margin-top: 10px;
        }

        .box_cardList .box_card:nth-child(3) .box_card-wrap .notes {
            @include rem(12);
            font-weight: 500;
            margin-top: 4px;
        }

        .box_cardList .box_card:nth-child(3) .box_card-wrap .button_lv1 {
            margin-top: 40px;

            @include media_query() {
                margin-top: 20px;
            }
        }

        .last_comment {
            margin-top: 100px;
        }

        .last_comment--text{
            @include rem(16);
            font-weight: bold;
            background-image:  url(../img/higashihiroshima/higashihiroshima04/img03.png);
            background-position: top left;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 20px;
            padding: 24px;
            width: 100%;
            max-width: 340px;
            text-align: justify;
        }

        .last_comment > img,
        .last_comment > picture {
            display: block;
            margin-top: -60px;

            @include media_query() {
                margin-top: 60px;
            }
        }


    } // section_higashihiroshima04_05


    .heading_lv1{
        @include rem(21);
        font-weight: bold;
        color: #fff;
        background-color: #F07800;
        width: 100%;
        border-radius: 999px;
        padding: 12px 34px;

        @include media_query(){
            @include rem(16);
        }
    }


    .advice_lv1{
        position: relative;
        background-image:  url(../img/higashihiroshima/higashihiroshima04/img03.png);
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 80px auto 0;
        padding: 24px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 570px;

        @include media_query(){
            display: block;
        }
    }

    .advice_lv1 p {
        @include rem(16);
        font-weight: bold;
        line-height: 2;
        padding-left: 30px;

        @include media_query(){
            padding-left: 0;
        }
    }

    .advice_lv1 img:nth-child(1) {
        width: 71px;
        height: 64px;
    }

    .advice_lv1 .face {
        position: absolute;
        bottom: -20px;
        right: -20px;
        width: 70px;
    }

    .button_lv1 {
        position: relative;
        width: 100%;
        max-width: 286.35px;
        border-radius: 999px;
        background: #fff;
        border: solid 2px #3690c0;
        @include rem(15);
        font-weight: bold;
        color: #3690c0;
        text-align: center;
        margin: 0 auto;
        padding: 20px;
        line-height: 1;
    }

    .button_lv1::before{
        content: '';
        display: block;
        background-image:  url(../img/higashihiroshima/higashihiroshima04/img27.png);
        background-position: top left;
        background-repeat: no-repeat;
        background-size: cover;
        width: 8px;
        height: 14px;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
    }

    .pc_hidden {
        display: none;

        @include media_query(){
            display: block;
        }
    }
}

.inline_a{
    display: inline-block !important;
}