@use '../base/vars' as *;

.education03, .education04{
  .pc {
    @media (min-width:641px) {
      display: block;
    }
    @include media_query() {
      display: none;
    }
  }
  .sp {
    @media (min-width:641px) {
      display: none;
    }
    @include media_query() {
      display: block;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

}//.education03, .education04


.education03 {
  .img01 {
    max-width: 486px;
  }

  .img02 {
    max-width: 250px;
  }

  .park_quantity {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    max-width: 800px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @include media_query() {
      flex-direction: column;
    }

    .park_quantity_item {
      margin-left: auto;
      margin-right: auto;
      @include media_query() {
        &:last-child {
          margin-top: 50px;
        }
      }
    }
  }

  .section {
    margin-top: 90px;
  }

  .park_list_title {
    margin-top: 60px;
    text-align: center;

    > * {
      display: inline-block;
      background-color: #66A581;
      border-radius: 5px;
      color: #fff;
      @include rem(24);
      padding: 20px 28px;
    }
  }

  .park_list_box {
    border-radius: 5px;
    display: flex;
    padding: 40px 30px;
    @include media_query() {
      flex-direction: column;
    }

    &.bg_green {
      margin-top: 45px;
      background-color: #EDF6E3;
    }

    .park_thum {
      margin-right: 50px;
      max-width: 410px;
      @include media_query() {
        margin-right: 0;
      }
    }

    .park_content {
      flex: 1;

      &_inner {
        display: flex;
        flex-direction: column;
        height: 100%;
      }

      .title {
        @include rem(21);
        line-height: 1.4;
        @include media_query() {
          margin-top: 30px;
        }
      }

      .facility {
        border-top: 1px solid #66A581;
        border-bottom: 1px solid #66A581;
        padding: 20px 0;
        margin: 20px 0 30px;
        display: flex;
        line-height: 1.6;

        .facility_head {
          font-weight: bold;
          width: 70px;
          margin-right: 20px;
        }

        .facility_list {
          flex: 1;
        }
      }

      .btn {
        margin-top: auto;
        @include media_query() {
          margin-top: 20px;
        }
      }
    }

    .park_txt {
      line-height: 1.6;
    }
  }
  //.park_list_box

  .park_pickup {
    margin-top: 70px;
  }

  .michinoeki {
    &_head {
      @include rem(26);
      font-weight: bold;
      border: solid 2px #66A581;
      border-radius: 10px 10px 0 0;
      padding: 20px;
      text-align: center;
      color: #66A581;
    }

    &_content {
      border: solid 2px #66A581;
      border-top: none;
      border-radius: 0 0 10px 10px;
    }
  }
  //.michinoeki
}
//.education03


.education04{
  &_section{
    margin-top: 100px;
    .education04_txt{
      @include rem(18);
      margin-top: 30px;
      line-height: 1.6;
    }
  }//.section

  .img01,.img02{
    max-width: 514px;
  }

  .img03{
    max-width: 178px;
  }

  .img04,.img05{
    max-width: 516px;
  }

  .img06{
    max-width: 236px;
  }

  &_quantity{
    display: flex;
    align-items: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 65px;
    @include media_query() {
      flex-direction: column;
    }
    .thum_left{
      margin-right: 50px;
      @include media_query() {
        margin-right: 0;
      }
      .img02,.img05{
        margin-top: 75px;
      }
    }
    .thum_right{
      @include media_query() {
        margin-top: 50px;
      }
    }
  }//&_quantity
  &_btn{
    margin-top: 65px;
  }
  &_pickup{
    margin-top: 55px;
  }
}
//.education04




// パーツ
.md-boxblue {
  background-color: #5E7BB1;
  border-radius: 10px;
  padding: 40px 30px;
  .md-boxblue_txt{
    @include rem(24);
    font-weight: bold;
    line-height: 1.5;
    color: #fff;
    text-align: center;
  }
  .btn_blue{
    margin-top: 30px;
  }
}

.md-half {
  display: flex;
  flex-wrap: nowrap;
  @include media_query() {
    flex-direction: column;
  }
  .md-half_item{
    width: calc(50% - 30px);
    @include media_query() {
      width: 100%;
    }

    & + .md-half_item {
      margin-left: 60px;
      @include media_query() {
        margin-left: 0;
        margin-top: 40px;
      }
    }
  }
}





.education05{
  
  .education05_section{
    .education05_section_imgs{
      margin: 60px auto 0;
      max-width: 700px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 30px;
      
      @include media_query(){
        margin: 30px auto 0;
        gap: 10px;
      }
      
      img{
        width: 100%;
        height: auto;
      }
    }
    
    .education05_section_texts{
      max-width: 920px;
      margin: 60px auto 0;
      display: grid;
      grid-template-columns: 480px 1fr;
      justify-content: center;
      align-items: center;
      gap: 20px;
      
      @include media_query(){
        margin: 30px auto 0;
        grid-template-columns: 1fr;
      }
      
      p{
        @include rem(16);
        line-height: 1.8;
      }
      
      .education05_section_texts_img{
        text-align: center;
        
        img{
          max-width: 70px;
          height: auto;
        }
      }
      
      & + .btn_blue{
        margin-top: 60px;
      }
    }
  }
  
}

.pageend_note{
  margin-top: 60px;
  
  & + .pageend_note{
    margin-top: 30px;
  }
  
  h2{
    @include rem(15);
    border-left: solid 3px #000;
    padding-left: 5px;
    margin-bottom: 10px;
  }
  
  p{
    @include rem(14);
    line-height: 1.8;
    
    a{
      color: #000;
      text-decoration: none;
      
      &:hover{
        color: #5E7BB1;
      }
    }
  }
}