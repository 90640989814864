@use '../base/vars' as *;

.reasons01 {
  img {
    max-width: 100%;
    height: auto;
  }

  .img02{
    max-width: 331px;
    width: 100%;
    @include media_query() {
      margin-top: 30px;
    }
  }
  .img03,.img04{
    max-width: 190px;
    width: 100%;
    margin-left: auto;
    @include media_query() {
      margin-right: auto;
      margin-top: 30px;
    }
  }

  .reasons01_img01{
    margin-top: 45px;
    max-width: 162px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .reasons01_img05{
    margin-top: 45px;
    max-width: 434px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .reasons01_box{
    max-width: 570px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .reasons01_wrap{
    margin-top: 135px;
  }

  .reasons01_h{
    text-align: center;
    margin-top: 60px;

    > * {
      display: inline-block;
      @include rem(24);
      padding: 0.8em 1.2em;
      border: 1px solid #696969;
      @include media_query() {
        @include rem(18);
      }
    }
  }

  .reasons01_flex {
    display: flex;
    flex: wrap;
    align-items: center;
    margin-top: 90px;
    @include media_query() {
      flex-direction: column;
    }

    .under_title_box {
      max-width: 230px;
      width: 100%;
      text-align: center;
    }
  }
  //.reasons01_flex

  .reasons01_pickup{
    margin-top: 55px;
  }
}//.reasons01

.article_body .article_body_contents.reasons01 .support_dl{
  margin-top: 65px;
}
