@use '../base/vars' as *;

.archives{
    padding-top: 200px;
    
    @include media_query(){
        padding-top: 80px;
    }
    
    .news_wrap{
        margin-top: 100px;
        
        @include media_query(){
            margin-top: 80px;
        }
    }
}

.single{
    max-width: 840px;
    margin: 100px auto 0;
    padding: 0 20px;
    
    @include media_query(){
        margin: 80px auto 0;
    }
    
    .single_img{
        img{
            max-width: 100%;
            height: auto;
            vertical-align: bottom;
        }
    }
    
    .single_head{
        margin-top: 70px;
        
        @include media_query(){
            margin-top: 25px;
        }
        
        h1{
            margin-top: 40px;
            @include rem(28);
            line-height: 1.7;
            
            @include media_query(){
                margin-top: 20px;
                @include rem(19);
            }
        }
    }
    
    .news_item_info{
        display: flex;
        align-items: center;
        
        @include media_query(){
            margin-top: 15px;
        }
        
        time{
            @include rem(16);
            color: $font-color;
            margin-right: 30px;
        }
        
        ul{
            display: flex;
            align-items: center;
            gap: 10px;
            
            li{
                list-style: none;
                text-decoration: none;
                @include rem(13);
                white-space: nowrap;
                color: #fff;
                background-color: $main-color;
                padding: 5px 8px;
                
                @include media_query(){
                    @include rem(16);
                }
                
                a{
                    color: #fff;
                    text-decoration: none;
                    transition: opacity .25s;
                    
                    &:hover{
                        opacity: .7;
                    }
                }
            }
        }
    }
    
    .single_main{
        margin-top: 65px;
        padding: 60px 0;
        border-top: solid 1px #4C4948;
        border-bottom: solid 1px #4C4948;
        
        @include rem(16);
        line-height: 2;
        
        @include media_query(){
            line-height: 1.7;
            margin-top: 25px;
            padding: 40px 0;
        }
        
        ul{
            padding-left: 1.2em;
            margin: 1em 0;
        }
        
        p{
            margin: 1em 0;
            
            &:first-child{
                margin-top: 0;
            }
        }
        
        img{
            max-width: 100%;
            height: auto;
        }
        
        figcaption{
            @include rem(14);
            color: #888;
            margin-top: -5px;
        }
        
        blockquote{
            background-color: #f2f2f2;
            padding: 1em;
            line-height: 1.6;
            
            cite{
                a{
                    color: $main-color;
                }
            }
        }
    }
    
    .single_foot{
        margin-top: 30px;
        
        .pager{
            display: flex;
            align-items: center;
            justify-content: center;
            
            a{
                text-decoration: none;
                color: $font-color;
            }
            
            .prev_btn{
                margin-right: auto;
                a{
                    position: relative;
                    padding: .8em;
                    
                    span{
                        @include rem(15);
                        display: flex;
                        align-items: center;
                        position: relative;
                        color: $font-color;
                        font-weight: bold;
                        transition: color .25s;
                        
                        @include media_query(){
                            @include rem(14);
                        }
                        
                        &::before{
                            display: block;
                            content: "";
                            width: 10px;
                            height: 10px;
                            border-top: 1px solid $font-color;
                            border-right: 1px solid $font-color;
                            transform: rotate(225deg);
                            margin-right: 10px;
                        }
                    }
                    
                    &:hover{
                        span{
                            color: $main-color;
                        }
                    }
                    
                }
                
                p{
                    padding: .8em;
                    
                    span{
                        @include rem(15);
                        display: flex;
                        align-items: center;
                        position: relative;
                        color: #B8B6B6;
                        font-weight: bold;
                        
                        @include media_query(){
                            @include rem(14);
                        }
                        
                        &::before{
                            display: block;
                            content: "";
                            width: 10px;
                            height: 10px;
                            border-top: 1px solid #B8B6B6;
                            border-right: 1px solid #B8B6B6;
                            transform: rotate(225deg);
                            margin-right: 10px;
                        }
                    }
                }
            }
            
            .return_btn{
                a{
                    padding: .8em;
                    @include rem(15);
                    padding-bottom: 5px;
                    border-bottom: solid 1px $font-color;
                    font-weight: bold;
                    
                    transition: color .25s, border-bottom .25s;
                    
                    @include media_query(){
                        @include rem(14);
                    }
                    
                    &:hover{
                        color: $main-color;
                        border-bottom: solid 1px $main-color;
                    }
                }
            }
            
            .next_btn{
                margin-left: auto;
                
                a{
                    position: relative;
                    padding: .8em;
                    
                    span{
                        @include rem(15);
                        display: flex;
                        align-items: center;
                        position: relative;
                        color: $font-color;
                        font-weight: bold;
                        transition: color .25s;
                        
                        @include media_query(){
                            @include rem(14);
                        }
                        
                        &::after{
                            display: block;
                            content: "";
                            width: 10px;
                            height: 10px;
                            border-top: 1px solid $font-color;
                            border-right: 1px solid $font-color;
                            transform: rotate(45deg);
                            margin-left: 10px;
                        }
                    }
                    
                    
                    &:hover{
                        span{
                            color: $main-color;
                        }
                    }
                    
                }
                
                p{
                    padding: .8em;
                    
                    span{
                        @include rem(15);
                        display: flex;
                        align-items: center;
                        position: relative;
                        color: #B8B6B6;
                        font-weight: bold;
                        
                        @include media_query(){
                            @include rem(14);
                        }
                        
                        &::after{
                            display: block;
                            content: "";
                            width: 10px;
                            height: 10px;
                            border-top: 1px solid #B8B6B6;
                            border-right: 1px solid #B8B6B6;
                            transform: rotate(45deg);
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }
}