@use '../base/vars'as *;

.business06 {

  ul {
    background-image: none !important;;
  }

  ul li {
    text-indent: 0 !important;

    &::before {
      content: none !important;
    }
  }

  & > p {
    font-size: 1rem !important;
    font-weight: 500;
  }


  .section_01 {

    ul {
      display: flex;
      flex-wrap: wrap;
      margin-top: -10%;
      margin-left: -10%;

      @media screen and (max-width: 960px) {
        margin-left: -5%;
      }

      @media screen and (max-width: 750px) {
        margin-top: -20%;
      }
    }

    ul li {
      width: 50%;
      padding-top: 10% !important;
      padding-left: 10% !important;

      @media screen and (max-width: 960px) {
        padding-left: 5% !important;
      }

      @media screen and (max-width: 750px) {
        width: 100%;
        padding-top: 20% !important;
      }
    }

    ul li img{
      display: block;
      width: 100%;
      height: auto;
    }

    ul li:nth-child(1) img{
      max-width: 260px;
      margin: 40px auto 0;
    }
    ul li:nth-child(2) img{
      max-width: 255px;
      margin: 40px auto 0;
    }
    ul li:nth-child(3) img{
      max-width: 176px;
      margin: 40px auto 0;
    }
    ul li:nth-child(4) img{
      max-width: 227px;
      margin: 40px auto 0;
    }

    ul li:nth-child(4) span:nth-child(1){
      @include rem(40);

      @media screen and (max-width: 960px) {
        @include rem(30);
      }
    }

    ul li:nth-child(4) span:nth-child(2),
    ul li:nth-child(4) span:nth-child(3){
      display: inline-block;
    }

    dl{
      color: #5E7BB1;
    }

    dt,
    dd {
      text-align: center;
    }

    dt{
      @include rem(24);
      font-weight: bold;;
      border-top: solid 3px #D7DEEB;
      border-bottom: solid 3px #D7DEEB;
      padding: 6px 0;

      @media screen and (max-width: 960px){
        @include rem(20);
      }
    }

    .data {
      font-weight: bold;
      line-height: 1;
      margin-top: 30px;
    }

    .data span:nth-child(1){
      line-height: 1.2;
    }

    .data span:nth-child(1),
    .data span:nth-child(3){
      @include rem(50);

      @media screen and (max-width: 960px) {
        @include rem(30);
      }
    }

    .data span:nth-child(2){
      @include rem(140);

      @media screen and (max-width: 960px) {
        @include rem(100);
      }
    }

    .data .item01{
      @include rem(50);
      display: block;

      @media screen and (max-width: 960px) {
        font-size: 1.5625rem !important;
      }
    }


    .data .circle{
      display: inline-block;
      background-color: #5E7BB1;
      width: 20px;
      height: 20px;
      border-radius: 999px;
      margin: 0 6px;
    }

    .data .circle02{
      display: inline-block;
      background-color: #5E7BB1;
      width: 10px;
      height: 10px;
      border-radius: 999px;
      margin: 0 6px;
    }

    .notes {
      @include rem(12);
      font-weight: 500;
      line-height: 1;
      text-align: right;
      padding-right: 12%;
      margin-top: 2px;
    }

  }

  .section_02{
    padding-top: 132px;

    h2{
      @include rem(26);
      font-weight: bold;
      text-align: center;
      line-height: 1.7;
      color: #5E7BB1;
      border-top: solid 2px #5E7BB1;
      border-bottom: solid 2px #5E7BB1;
      padding: 20px 0;

      @include media_query(){
        @include rem(20);
      }
    }

    .section_02-content{
      margin-top: 52px;
      border-radius: 10px;
      color: #fff;
      background-color: #5E7BB1;
      padding: 40px;
      display: flex;
      justify-content: space-around;

      @media screen and (max-width: 873px){
        display: block;
        padding: 40px 20px;
      }
    }

    .section_02-content h3{
      @include rem(26);
      font-weight: bold;
    }

    .section_02-content p{
      @include rem(16);
      font-weight: 500;
      margin-top: 20px;
      line-height: 1.7;
    }

    .section_02-content .item02 {
      display: flex;
      align-items: flex-end;
      justify-content: left;

      @media screen and (max-width: 873px){
        margin-top: 40px;
        justify-content: center;
      }
    }

    .section_02-content .item02 img:nth-child(1){
      width: 100%;
      max-width: 98px;
      display: block;
    }

    .section_02-content .item02 img:nth-child(2){
      width: 100%;
      max-width: 150px;
      display: block;
      box-shadow: 6px 6px 0px 0px rgba(76,73,72, 0.6);
      margin-left: 30px;
    }

    .section_02-content a{
      @include rem(17);
      position: relative;
      display: block;
      font-weight: bold;
      width: 100%;
      max-width: 300px;
      border-radius: 32px;
      background: #89b9ee;
      text-align: center;
      color: #fff;
      margin: 60px 0 0;
      padding: 26px 0;
      transition: opacity .5s ease-out;

      @media screen and (max-width: 873px){
        margin: 60px auto 0;
      }
    }

    .section_02-content a::after{
      content: '';
      display: block;
      background-image:  url(../img/business/business06/img07@2x.png);
      background-position: top left;
      background-repeat: no-repeat;
      background-size: cover;
      width: 9px;
      height: 15px;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }

    .section_02-content a:hover{
      opacity: .5;
      cursor: pointer;
    }
  }

  .sp_hiddden{
    display: block;

    @include media_query(){
      display: none;
    }
  }
}