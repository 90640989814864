@use '../base/vars' as *;

.education06{
  color: #4C4948;

  p {
   font-size: 1rem !important;
   font-weight: 500;
  }

  .pc_hidden{
    display: none;

    @include media_query(){
      display: block;
    }
  }

  .lead {
    text-indent: 1rem;
  }

  .education06_01{
    padding-top: 90px;
    
    @include media_query(){
      padding: 0;
    }

    h2{
      @include rem(18);
      text-align: center;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      padding: 14px;
      border: solid 1px #4C4948;
      line-height: 1.3;
    }

    ul {
      padding: 0 !important;
      background-image: none !important;
    }

    li {
      padding-top: 60px;
      padding-left: 0 !important;
      text-indent: 0 !important;
    }

    li::before{
      content: none !important;
    }

    dl {
      color: #5E7BB1;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 820px){
        display: block;
      }
    }

    dt {
      @include rem(28);
      font-weight: bold;
      width: 50%;
      text-align: center;

      @media screen and (max-width: 820px){
        width: 100%;
      }
    }

    dt span {
      background: linear-gradient(transparent 60%, #FFF35F 50%);
    }

    dd {
      @include rem(120);
      font-weight: 600;
      width: 50%;
      line-height: 1;
      text-align: left;

      @media screen and (max-width: 820px){
        @include rem(80);
        width: 100%;
        text-align: center;
      }
    }

    dd span {
      @include rem(40);
      font-weight: 600;

      @media screen and (max-width: 820px){
        @include rem(22);
      }
    }

    .notes {
      font-size: 0.75rem !important;
      font-weight: 500;
      text-align: right;
      color: #5E7BB1;
      margin-top: 20px;
      padding-right: 12%;
    }

  } // education06_01

  .education06_02{
    padding-top: 160px;
    
    @include media_query(){
      padding-top: 60px;
    }

    h2{
      @include rem(26);
      font-weight: bold;
      text-align: center;
      padding: 12px 0;
      border-top: solid 4px #C4DCF7;
      border-bottom: solid 4px #C4DCF7;
      color: #5E7BB1;
    }

    img {
      width: 100%;
      max-width: 508px;
      margin: 60px auto 0;
      display: block;
    }

    p{
      margin-top: 60px;
      text-indent: 1rem;
      line-height: 1.8;
      letter-spacing: 0.025em;
    }
  } // education06_02

  .education06_03 {
    padding-top: 100px;

    h2{
      @include rem(26);
      font-weight: bold;
      text-align: center;
      padding: 12px 0;
      border-top: solid 4px #C4DCF7;
      border-bottom: solid 4px #C4DCF7;
      color: #5E7BB1;
    }

    .lists {
      margin-top: 50px;
      display: flex;

      @include media_query(){
        display: block;
      }
    }

    .lists .item01 {
      padding-right: 2%;

      @include media_query(){
        padding-right: 0;
      }
    }

    .lists .item01 ul {
      padding: 0;
      background-image: none;
    }

    .lists .item01 li{
      @include rem(17);
      font-weight: bold;
    }

    .lists .item01 li::before{
      @include rem(17);
      font-weight: bold;
      color: #89B9EE;
    }

    .lists .item01 p {
      font-size: 0.875rem !important;
      font-weight: 500;
      line-height: 1.5;
      margin-top: 16px;
    }

    .lists img {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 240px;
      height: 100%;

      @include media_query(){
        margin-top: 20px;
      }
    }

    .question{
      width: 100%;
      max-width: 420px;
      border-radius: 16px;
      background: #dceafa;
      margin: 120px auto;
      padding: 60px 60px 40px;
      text-align: center;
    }

    .question h3{
      color: #5E7BB1;
      text-align: center;
      @include rem(26);
      font-weight: bold;
      margin-top: 30px;
    }

    .question img {
      width: 100%;
      max-width: 256px;
    }

    .question a {
      position: relative;
      display: block;
      @include rem(17);
      font-weight: bold;
      color: #5E7BB1;
      width: 100%;
      max-width: 300px;
      border-radius: 999px;
      background: #fff;
      border: solid 2px #5e7bb1;
      margin: 36px auto 0;
      padding: 18px 10px;


      &:hover{
        opacity: .5;
        transition: opacity .5s ease-out;
        cursor: pointer;
      }
    }

    .question a::after{
      content: '';
      display: block;
      background-image:  url(../img/education/education06/img04.png);
      background-position: top left;
      background-repeat: no-repeat;
      background-size: cover;
      width: 9px;
      height: 15px;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }
  } // education06_03
}