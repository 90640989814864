@use '../base/vars' as *;

.life07{
    .img01{
        margin-top: 50px;
        text-align: center;
        
        img{
            width: 100%;
            max-width: 257px;
            height: auto;
        }
    }
    
    .university{
        margin-top: 55px;
        
        dl{
            display: flex;
            align-items: center;
            
            @include media_query(){
                flex-direction: column;
                justify-content: center;
            }
        }
        
        .university_inner{
            display: flex;
            align-items: center;
            
            @include media_query(){
                flex-direction: column;
            }
            
            &:first-child{
                margin-right: auto;
                
                @include media_query(){
                    margin-right: 0;
                    margin-bottom: 30px;
                }
            }
            
            dt{
                display: flex;
                justify-content: center;
                align-items: center;
                width: 96px;
                height: 96px;
                color: #5E7BB1;
                background-color: #E7F1FC;
                border-radius: 50%;
                @include rem(24);
                
                @include media_query(){
                    margin-bottom: 20px;
                }
            }
            
            dd{
                margin-left: 15px;
                
                .img06{
                    width: 100%;
                    max-width: 108px;
                    height: auto;
                }
                
                .img07{
                    width: 100%;
                    max-width: 433px;
                    height: auto;
                }
            }
        }
    }
    
    .university_name{
        max-width: 670px;
        margin: 0 auto;
        
        @include media_query(){
            margin-top: 40px;
        }
        
        ul{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
            background: none;
            
            @include media_query(){
                grid-template-columns: 1fr;
                gap: 20px;
            }
            
            li{
                list-style: none;;
                @include rem(26);
                background-color: #5E7BB1;
                text-align: center;
                color: #fff;
                padding: 0;
                text-indent: 0;
                
                &::before{
                    display: none;
                }
            }
        }
    }
    
    .university_activities{
        margin-top: 45px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
        
        @include media_query(){
            grid-template-columns: 1fr;
        }
        
        .university_activities_item{
            background-color: #E7F1FC;
            border-radius: 20px;
            padding: 30px 45px;
            
            @include media_query(){
                padding: 20px;
            }
            
            h4{
                @include rem(24);
                line-height: 1.6;
                text-align: center;
                margin-bottom: 30px;
                
                @include media_query(){
                    margin-bottom: 20px;
                }
            }
            
            img{
                width: 100%;
                height: auto;
            }
            
            p{
                @include rem(16);
                line-height: 1.6;
                margin-top: 25px;
                
                @include media_query(){
                    margin-top: 15px;
                }
            }
        }
    }
    
}




.life08{
    .img01{
        margin-top: 50px;
        text-align: center;
        
        img{
            width: 100%;
            max-width: 314px;
            height: auto;
        }
    }
    
    .opinion{
        .opinion_item{
            margin-top: 50px;
            display: flex;
            
            @include media_query(){
                flex-direction: column;
                align-items: center;
            }
            
            & + .opinion_item{
                margin-top: 50px;
            }
            
            dt{
                display: flex;
                @include rem(24);
                line-height: 1.6;
                font-weight: bold;
                
                @include media_query(){
                    @include rem(18);
                    text-align: center;
                }
                
                &::before{
                    display: block;
                    content: "";
                    width: 20px;
                    height: 20px;
                    background-color: #5E7BB1;
                    border-radius: 50%;
                    margin: 7px 10px 0 0;
                    
                    @include media_query(){
                        display: none;
                    }
                }
            }
            
            dd{
                margin-left: auto;
                
                @include media_query(){
                    margin-left: 0;
                    margin-top: 20px;
                }
                
                img{
                    width: 100%;
                    height: auto;
                    
                    @include media_query(){
                        transform: scale(0.8);
                    }
                }
                
                .img02 img{
                    max-width: 178px;
                }
                .img03 img{
                    max-width: 194px;
                }
                .img04{
                    max-width: 307px;
                }
            }
        }
    }
    
    .side{
        margin-top: 50px;
        display: flex;
        align-items: center;
        gap: 30px;
        
        @include media_query(){
            flex-direction: column;
        }
        
        .side_img{
            img{
                width: 100%;
                max-width: 410px;
                height: auto;
            }
        }
        
        .side_text{
            flex: 1;
            
            h4{
                @include rem(21);
                margin-bottom: 20px;
            }
            
            p{
                @include rem(16);
                line-height: 1.6;
            }
        }
    }
    
    h3 + p{
        margin-top: 50px;
        @include rem(18);
        line-height: 1.6;
    }
}