@use '../base/vars' as *;

.education07{

  .education07_01 h2,
  .education07_02 h2,
  .education07_03 h2{
    @include rem(21);
    font-weight: bold;
    text-align: center;
    color: #fff;
    background-color: #5E7BB1;
    border-radius: 5px;
    padding: 12px 0;
  }

  .education07_01{

    h3{
      @include rem(26);
      font-weight: bold;;
      border-top: solid 4px #C4DCF7;
      border-bottom: solid 4px #C4DCF7;
      padding: 16px 8px;
      text-align: center;
      color: #5E7BB1;
      line-height: 1.2;
    }

    .education07_01-box{
      margin-top: 34px;
      display: flex;

      @include media_query(){
        display: block;
      }
    }

    .education07_01-box .item01{
      width: 60%;

      @include media_query(){
        width: 100%;
      }
    }

    .education07_01-box p{
      @include rem(16);
      font-weight: 500;
      line-height: 2;
      text-indent: 1rem;
    }

    .education07_01-box img {
      width: 100%;
      height: 137px;
      max-width: 155px;
      margin: 0 auto;
      display: block;

      @include media_query(){
        margin: 30px auto 0;
      }
    }

    ._link{
      margin: 60px auto 0;
    }

    .stage{
      margin-top: 90px;
    }

    .stage p {
      @include rem(16);
      font-weight: 500;
      line-height: 2;
      text-indent: 1rem;
      margin-top: 40px;
    }
  }

.education07_02{
  padding-top: 78px;

  h2{
    position: relative;
    padding: 20px 0;
  }

  h2::before{
      content: '';
      display: block;
      background-image:  url(../img/education/education07/img03@2x.png);
      background-position: top left;
      background-repeat: no-repeat;
      background-size: cover;
      width: 62px;
      height: 76px;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 2%;

      @media screen and (max-width: 950px){
        top: -30px;
        bottom: auto;
      }
  }

  h2::after{
    content: '';
    display: block;
    background-image:  url(../img/education/education07/img04@2x.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    width: 126px;
    height: 82px;
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0px;

    @media screen and (max-width: 1000px){
      width: 101.32px;
      height: 66.14px;
      top: auto;
      bottom: 0;
      right: 0px;
    }

    @media screen and (max-width: 950px){
      top: -20px;
      bottom: auto;
      right: -20px;
    }
  }

  h2 span {
    display: block;
    line-height: 1.2;
  }

  h2 span:nth-child(2){
    margin-top: 10px;
  }

  
  
  .img05{
      display: block;
      margin-top: 34px;
      
      @include media_query(){
          width: 100%;
          overflow: scroll;
      }
      
    img{
      width: 100%;
      max-width: 100%;
      
      @include media_query(){
        width: 750px;
        max-width: inherit;
      }
    }
  }
}


.education07_03{
  padding-top: 80px;

  & > p {
    @include rem(16);
    font-weight: 500;
    line-height: 2;
    text-indent: 1rem;
    margin-top: 34px;
  }

  .support{
    margin-top: 66px;
  }

  .support h3{
    @include rem(18);
    font-weight: bold;
    text-align: center;
  }

  .support ._link {
    margin: 56px auto 0;
  }

  .grid_box{
    background-image: none;;
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 26px auto 0;
    padding: 0;
    border: solid 1px #C9C8C8;
    width: 100%;
    max-width: 600px;
  }

  .grid_box li {
    text-indent: 0;
    padding-left: 0;

    &::before {
      content: none;
    }
    
    span{
      text-align: center;
    }
    
  }
  .grid_box li:nth-child(1){
    border-right: solid 1px #fff;
  }

  .grid_box li:nth-child(1),
  .grid_box li:nth-child(2){
    @include rem(16);
    font-weight: bold;
    background-color: #DBDBDA;
    text-align: center;
    padding: 6px;
  }

  .grid_box li:nth-child(3),
  .grid_box li:nth-child(5),
  .grid_box li:nth-child(7){
    background-color: #E7F1FC;
    color: #5E7BB1;
    @include rem(16);
    font-weight: bold;
    border-top: solid 1px #C9C8C8;
    border-right: solid 1px #C9C8C8;
    display: flex;
    justify-content: center;
    align-items: center;

    span{
      display: block;
    }
  }

  .grid_box li:nth-child(4),
  .grid_box li:nth-child(6),
  .grid_box li:nth-child(8){
    @include rem(16);
    font-weight: 500;
    line-height: 1.8;
    padding: 14px;
    border-top: solid 1px #C9C8C8;
  }

  .others{
    width: 100%;
    max-width: 420px;
    background-color: #dceafa;
    margin: 120px auto 0;
    padding: 40px 60px;
    border-radius: 16px;

    @include media_query(){
      padding: 40px 20px;
    }
  }

  .others img{
    width: 118px;
    height: 126px;
    display: block;
    margin: 0 auto;
  }

  .others P{
    @include rem(26);
    font-weight: bold;
    line-height: 1.5;
    color: #5E7BB1;
    text-align: center;
    margin-top: 26px;
  }

  .others ._link{
    margin: 40px auto 0;
  }
}


  ._link{
    @include rem(17);
      position: relative;
      display: block;
      font-weight: bold;
      width: 100%;
      max-width: 300px;
      border-radius: 999px;
      background: #fff;
      text-align: center;
      color: #5E7BB1;
      padding: 20px 0;
      transition: opacity .5s ease-out;
      border: solid 2px #5e7bb1;
  }

  ._link:hover{
    opacity: .5;
  }

  ._link::after{
    content: '';
    display: block;
    background-image:  url(../img/education/education07/img02@2x.png);
    background-position: top left;
    background-repeat: no-repeat;
    background-size: cover;
    width: 9px;
    height: 15px;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
  }
}