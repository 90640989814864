@use '../base/vars' as *;

.f-mincho{
  font-family: 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', '游明朝','Yu Mincho', '游明朝体', 'YuMincho','ＭＳ Ｐ明朝', 'MS PMincho', serif;
}

.interview_head {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: solid 1px #B3B1B1;
  color: #5E7BB1;
  font-weight: 600;
  text-align: center;
  @include rem(26);
  @include media_query() {
    @include rem(20);
    padding-top: 20px;
    padding-bottom: 20px;
  }
}//.interview_head

.interview_wrap{
  margin-top: 40px;
  padding-bottom: 50px;
  img{
    max-width: 100%;
    height: auto;
  }
  .article_body_head{
    padding-bottom: 35px;
  }
  .article_body_head h1 {
    @extend .f-mincho;
  }

}//.interview_wrap

.interview_container{
  max-width: 670px;
  width: 100%;
  margin: 60px auto;
  @include media_query() {
    margin: 40px auto;
  }
  .interview_container_head{
    .title_sub{
      color: #5E7BB1;
      font-weight: bold;
      position: relative;
      padding-left: 16px;
      padding-bottom: 20px;
      @include rem(18);
      &::before,&::after{
        content: '';
        position: absolute;
        left: 0;
        background-color: #5E7BB1;
      }
      &::before{
        width: 14px;
        height: 14px;
        border-radius: 50%;
        top: 2px;
      }
      &::after{
        width: 72px;
        height: 1px;
        bottom: 0;
      }
    }
    .title_main{
      margin-top: 30px;
      line-height: 1.5;
      letter-spacing: .03em;
      font-weight: bold;
      @extend .f-mincho;
      @include rem(30);
      @include media_query() {
        @include rem(20);
      }
    }
  }
  .interview_content{
    margin-top: 40px;
    line-height: 2;
    @include media_query() {
      margin-top: 30px;
    }
  }
}//.interview_container
