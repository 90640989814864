@use '../base/vars' as *;

.article{
    padding-top: 100px;

    @include media_query(){
        padding-top: 80px;
    }
}

.article_inner{
    max-width: 1080px;
    margin: 0 auto;
    padding: 0 40px;

    @include media_query(){
        padding: 0 20px;
    }
}

.article_head{
    padding-bottom: 50px;
    border-bottom: solid 1px #B3B1B1;
    text-align: center;

    & + .article_list{
        margin-top: 60px;

        @include media_query(){
            margin-top: 40px;
        }
    }

    .cat_icon{
        margin-top: 50px;

        @include media_query(){
            margin-top: 35px;
        }

        img{
            width: auto;
            vertical-align: bottom;
        }

        &.reasons{
            img{
                max-height: 88px;
            }
        }

        &.life{
            img{
                max-height: 88px;
            }
        }

        &.education{
            img{
                max-height: 88px;
            }
        }

        &.business{
            img{
                max-height: 88px;
            }
        }
    }

    .cat_title{
        margin-top: 35px;

        @include media_query(){
            margin-top: 20px;
        }

        span{
            @include rem(14);
            font-family: 'Barlow Semi Condensed', sans-serif;
            font-weight: 500;
            color: $main-color;

            @include media_query(){
                @include rem(10);
            }
        }

        h3{
            margin-top: 15px;
            @include rem(48);
            letter-spacing: .1em;

            @include media_query(){
                @include rem(34);
            }
        }
    }
}





.article_list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 60px 35px;

    @include media_query(){
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: 40px;
    }
}

.article_item{

    .article_item_img{
        border-radius: 12px;
        overflow: hidden;

        img{
            width: 100%;
            height: auto;
            aspect-ratio: 310 / 200;
            object-fit: cover;
            vertical-align: bottom;
            transition: opacity .25s;

            &:hover{
                opacity: .7;
            }
        }
    }

    .article_item_info{
        display: flex;
        align-items: center;
        margin-top: 24px;

        .icon_new{
            width: 100%;
            max-width: 53px;
            height: 20px;
            margin-right: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'Barlow Semi Condensed', sans-serif;
            font-weight: 600;
            @include rem(12);
            letter-spacing: .25em;
            color: #fff;
            background-color: #89B9EE;
            border-radius: 3px;
        }

        .datetime{
            @include rem(13);
            letter-spacing: .05em;
        }
    }

    h1{
        @include rem(18);
        letter-spacing: .03em;
        line-height: 1.5;
        margin-top: 15px;

        a{
            color: $font-color;
            text-decoration: none;
            transition: opacity .25s;

            &:hover{
                opacity: .7;
            }
        }
    }

    .article_item_foot{
        margin-top: 15px;

        ul{
            display: flex;

            li{
                list-style: none;
                a{
                    @include rem(12);
                    text-decoration: none;
                    color: $main-color;
                    transition: opacity .25s;

                    &:hover{
                        opacity: .7;
                    }

                    &::before{
                        content: "#";
                    }
                }
            }
        }
    }
}










.article_body{
    .article_body_head{
        margin-top: 50px;
        padding-bottom: 50px;
        border-bottom: solid 1px #B3B1B1;

        @include media_query(){
            margin-top: 25px;
            padding-bottom: 25px;
        }

        .article_body_info{
            display: flex;
            align-items: center;

            .icon_new{
                width: 100%;
                max-width: 68px;
                height: 27px;
                margin-right: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: 'Barlow Semi Condensed', sans-serif;
                font-weight: 600;
                @include rem(16);
                letter-spacing: .25em;
                color: #fff;
                background-color: #89B9EE;
                border-radius: 3px;

                @include media_query(){
                    max-width: 57px;
                    height: 22px;
                    @include rem(13);
                }
            }

            .datetime{
                @include rem(15);
                letter-spacing: .05em;

                @include media_query(){
                    @include rem(12);
                }
            }
        }

        h1{
            @include rem(32);
            line-height: 1.5;
            letter-spacing: .03em;
            margin-top: 20px;

            @include media_query(){
                @include rem(21);
                margin-top: 15px;
            }
        }

        .article_body_cat{
            margin-top: 20px;

            @include media_query(){
                margin-top: 15px;
            }

            ul{
                display: flex;

                li{
                    list-style: none;
                    a{
                        @include rem(15);
                        text-decoration: none;
                        color: $main-color;
                        transition: opacity .25s;

                        @include media_query(){
                            @include rem(12);
                        }

                        &:hover{
                            opacity: .7;
                        }

                        &::before{
                            content: "#";
                        }
                    }
                }
            }
        }
    }

    .article_body_contents{
        max-width: 980px;
        padding: 50px 20px;
        margin: 0 auto;
        
        a{
            // color: #231815;
            text-decoration: none;
            
            &:hover{
                color: #5e7bb1;
            }
        }
        
        @include media_query(){
            padding: 35px 0;
        }

        .section{
            & + .section{
                margin-top: 145px;

                @include media_query(){
                    margin-top: 80px;
                }
            }
        }

        &>p{
            @include rem(22);
            line-height: 1.7;

            @include media_query(){
                @include rem(18);
            }

            &:nth-of-type(1):not(.article_small){
                margin-bottom: 60px;
            }
        }

        .text_side{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 100px;

            @include media_query(){
                flex-direction: column;
                margin-bottom: 25px;
            }

            .underline{
                position: relative;
                top: 15px;
                padding-bottom: 3px;
                @include rem(52);
                border-bottom: solid 3px #D1AE00;
                font-weight: bold;

                @include media_query(){
                    @include rem(28);
                }
            }

            p{
                color: $main-color;
                margin: 0;

                & + p{
                    margin-left: 15px;

                    @include media_query(){
                        margin: 20px 0 0 0;
                    }
                }
            }

            .font_s{
                @include rem(42);
                font-weight: bold;
                line-height: 1;

                @include media_query(){
                    @include rem(28);
                }
            }

            .font_l{
                @include rem(147);
                font-weight: bold;
                line-height: 1;

                @include media_query(){
                    @include rem(100);
                }
            }

            .font_m{
                @include rem(60);
                font-weight: bold;
                line-height: 1;

                @include media_query(){
                    @include rem(35);
                }
            }

            & + p{
               color: $main-color;
               @include rem(13);
            }
        }

        .article_img{
            text-align: center;

            img{
                width: 100%;
                max-width: 100%;
                height: auto;
                vertical-align: bottom;
            }

            & + .article_img_side{
                margin-top: 80px;
            }

            & + .article_img{
                margin-top: 40px;
            }
        }

        .article_img_side{
            display: flex;
            gap: 40px;

            @include media_query(){
                flex-direction: column;
                gap: 20px;
            }

            img{
                flex: 1;
                width: 100%;
                max-width: 100%;
                height: auto;
                vertical-align: bottom;
            }
        }

        .article_title{
            @include rem(26);
            line-height: 1.5;
            color: $main-color;
            text-align: center;
            border-top: solid 3px $main-color;
            border-bottom: solid 3px $main-color;
            padding: 15px 0;

            @include media_query(){
                @include rem(21);
            }
        }

        .article_question{
            display: flex;
            align-items: center;
            margin: 50px 0 60px;
            line-height: 1.6;

            @include media_query(){
                @include rem(19);
            }

            span{
                flex: 1;
            }

            &::before{
                flex-basis: 36px;
                display: block;
                content: "";
                width: 100%;
                max-width: 36px;
                height: 47px;
                background-image: url(../img/life/life03/icon_question.png);
                background-repeat: no-repeat;
                background-size: 36px auto;
                background-position: center center;
                margin-right: 20px;

                @include media_query(){
                    position: relative;
                    top: -20px;
                    width: 28px;
                    height: 38px;
                    background-size: contain;
                    margin-right: 15px;
                }
            }
        }

        .underline_title{
            max-width: 780px;
            margin: 0 auto;
            position: relative;
            text-align: center;
            padding: 40px 0 90px;
            background-image: url(../img/life/life05/img01.png), url(../img/life/life05/img02.png);
            background-repeat: no-repeat, no-repeat;
            background-position: left 80px top, right bottom;
            background-size: 72px 72px, 157px 100px;

            @include media_query(){
                background-position: left top, right bottom 30px;
                background-size: 49px 49px, 117px 57px;
            }

            span{
                @include rem(52);
                padding-bottom: 3px;
                border-bottom: solid 3px #D1AE00;
                color: #5E7BB1;

                @include media_query(){
                    @include rem(28);
                }
            }
        }

        .tac{
            text-align: center;
        }

        .article_title_wrap{
            position: relative;
            text-align: center;
            margin-top: 120px;

            &::before{
                position: absolute;
                left: 0;
                top: -50px;
                display: block;
                content: "";
                width: 171px;
                height: 129px;
                background-image: url(../img/life/life03/img04.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: left center;
                margin-right: 20px;

                @include media_query(){
                    display: none;
                }
            }

            &::after{
                position: absolute;
                right: 0;
                top: -50px;
                display: block;
                content: "";
                width: 159px;
                height: 137px;
                background-image: url(../img/life/life03/img05.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: left center;
                margin-right: 20px;

                @include media_query(){
                    display: none;
                }
            }

            .article_title{
                position: relative;
                display: inline-block;
                padding: 15px 45px;

                @include media_query(){
                    display: block;
                    padding: 15px 0;
                }
            }
        }

        .article_small{
            margin-top: 155px;
            @include rem(13);
            line-height: 1.6;
            color: $font-color;

            @include media_query(){
                margin-top: 70px;
            }

            & + .article_small{
                margin-top: 0;

                @include media_query(){
                    margin-top: 0;
                }
            }

            a{
                color: $font-color;
                text-decoration: none;
                word-break: break-all;

                transition: color .25s;

                &:hover{
                    color: $main-color;
                }
            }
        }

        .img_small{
            text-align: center;
            @include rem(18);
            margin-top: 60px;
        }

        .article_question_side{
            display: flex;
            align-items: center;

            @include media_query(){
                flex-direction: column;
            }

            .article_question{
                flex: 1;

                @include media_query(){
                    margin-bottom: 15px;
                }
            }

            .article_img{
                margin-left: 25px;

                img{
                    max-width: 258px;
                    height: auto;

                    @include media_query(){
                        max-width: 195px;
                    }
                }
            }

            & + .article_title{
                margin-top: 145px;
            }
        }

        .support_dl{
            max-width: 759px;
            margin: 0 auto;

            .dl_wrap{
                display: flex;
                align-items: center;

                @include media_query(){
                    flex-direction: column;
                }

                & + .dl_wrap{
                    margin-top: 60px;
                }

                dt{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    max-width: 320px;
                    height: 96px;
                    @include rem(24);
                    line-height: 1.6;
                    color: #fff;
                    background-color: $main-color;
                    border-radius: 5px;

                    @include media_query(){
                        max-width: 100%;
                        @include rem(19);
                    }
                }

                dd{
                    @include rem(18);
                    line-height: 1.6;
                    margin-left: 30px;

                    @include media_query(){
                        margin: 20px 0 0 0;
                        @include rem(16);
                    }

                    span{
                        color: $main-color;
                        @include rem(60);
                        font-weight: bold;
                        line-height: 1;

                        @include media_query(){
                            @include rem(48);
                        }
                    }

                    i{
                        color: $main-color;
                        @include rem(24);
                        font-weight: bold;
                        font-style: normal;

                        @include media_query(){
                            @include rem(19);
                        }
                    }
                }
            }
        }

        ul{
            padding: 70px 0;
            background-image: url(../img/education/education01/img05.png);
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 169px 172px;

            @include media_query(){
                background-image: none;
                padding: 0;
                margin-top: 20px;
            }

            li{
                list-style: none;
                @include rem(24);
                line-height: 2;
                padding-left:1.2em;
                text-indent:-1.2em;

                @include media_query(){
                    @include rem(19);
                }

                &::before{
                    content: "●";
                    color: $main-color;
                    @include rem(24);
                    margin-right: 5px;

                    @include media_query(){
                        @include rem(19);
                    }
                }
            }
        }

        .yellow_title{
            margin-top: 60px;
            @include rem(21);
            line-height: 1.5;
            color: #4C4948;
            text-align: center;
            border-top: solid 2px #D1AE00;
            border-bottom: solid 2px #D1AE00;
            padding: 15px 0;

            @include media_query(){
                @include rem(15);
                margin-top: 30px;
                text-align: left;

                br{
                    display: none;
                }
            }
        }

        .sidebyside{
            margin-top: 65px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap: 80px;

            @include media_query(){
                margin-top: 40px;
                grid-template-columns: auto;
                grid-template-rows: repeat(2, 1fr);
                gap: 40px;
            }

            .side_item{
                img{
                    width: 100%;
                    height: auto;
                    vertical-align: bottom;
                }

                .side_item_title{
                    @include rem(21);
                    text-align: center;
                    margin-top: 35px;

                    @include media_query(){
                        @include rem(19);
                        margin-top: 25px;
                    }
                }

                p{
                    @include rem(16);
                    margin-top: 20px;
                    line-height: 1.6;

                    @include media_query(){
                        margin-top: 10px;
                    }
                }

                .side_item_img{
                    margin-top: 45px;
                    text-align: center;

                    @include media_query(){
                        margin-top: 20px;
                    }
                }
            }
        }



        &.life01{
            .train_animate{
                position: relative;
                text-align: center;
                max-width: 894px;

                img{
                    width: 100%;
                    height: auto;
                }

                .train{
                    position: absolute;
                    left: 30%;
                    bottom: 50px;
                    width: 20.805%;
                    max-width: 186px;

                    @include media_query(){
                        width: 28%;
                        max-width: 110px;
                        bottom: 110px;
                        animation-name: train_sp;
                    }

                    @keyframes train{
                        0%{
                            left: 30%;
                        }
                        10%{
                            left: 30%;
                        }
                        45%{
                            left: 51%;
                        }
                        55%{
                            left: 51%;
                        }
                        100%{
                            left: 30%;
                        }
                    }

                    @keyframes train_sp{
                        0%{
                            left: 25%;
                        }
                        10%{
                            left: 25%;
                        }
                        45%{
                            left: 50%;
                        }
                        55%{
                            left: 50%;
                        }
                        100%{
                            left: 25%;
                        }
                    }

                    animation-name: train;
                    animation-duration: 7s;
                    animation-iteration-count: infinite;
                }
            }
        }

        &.life03{
            .img01{
                text-align: center;
                margin-top: 45px;

                img{
                    max-width: 214px;

                    @include media_query(){
                        max-width: 154px;
                    }
                }
            }

            .graph01{
                text-align: center;

                img{
                    max-width: 540px;

                    @include media_query(){
                        max-width: 265px;
                    }
                }
            }

            .ranking{
                display: flex;
                flex-direction: column;
                margin-top: 70px;
                gap: 35px;

                @include media_query(){
                    align-items: center;
                }

                img{
                    width: 100%;
                    max-width: 785px;
                    height: auto;

                    @include media_query(){
                        max-width: 283px;
                    }
                }
            }
        }

        &.life04{
            .graph01{
                text-align: center;
                margin-top: 100px;

                @include media_query(){
                    margin-top: 60px;
                }

                img{
                    max-width: 780px;
                }
            }

            .graph01{
                text-align: center;
                margin-top: 130px;

                @include media_query(){
                    margin-top: 60px;
                }

                img{
                    max-width: 840px;
                }
            }

            .graph02{
                margin-top: 150px;

                @include media_query(){
                    margin-top: 100px;
                }
            }
        }

        &.life06{
            .img01{
                text-align: center;

                img{
                    max-width: 920px;
                }
            }
        }

        &.life06{
            .img01{
                text-align: center;

                img{
                    max-width: 660px;
                }
            }

            .img02{
                text-align: center;

                img{
                    max-width: 860px;
                    text-align: left;

                    @include media_query(){
                        max-width: 260px;
                    }
                }
            }
        }

        &.education01{
            .img01{
                text-align: center;

                img{
                    max-width: 700px;
                    height: auto;
                }
            }

            .img04{
                text-align: center;
                margin-top: 45px;
                margin-bottom: 45px;

                img{
                    max-width: 144px;
                }
            }
        }

        &.education02{
            .sidebyside{
                .side_item{
                    .side_item_img{
                        img{
                            max-width: 112px;
                            height: auto;

                            @include media_query(){
                                max-width: 90px;
                            }
                        }
                    }
                }
            }
        }
    }
}





.recommend_article{
    margin-top: 110px;

    @include media_query(){
        margin-top: 80px;
    }

    .recommend_article_title{
        @include rem(26);
        letter-spacing: .1em;
        text-align: center;
        margin-bottom: 100px;

        @include media_query(){
            margin-bottom: 60px;
        }
    }

    .recommend_article_wrap{
        position: relative;
    }

    .button_next {
        position: absolute;
        top: 80px;
        right: -20px;
        z-index: 99;
        width: 40px;
        height: 40px;
        background-color: rgba(#4C4948, .4);
        background-image: url(../img/icon_gt.png);
        background-size: 6px 12px;
        background-repeat: no-repeat;
        background-position: center center;
        cursor: pointer;

        &:hover{
            background-color: rgba(#5E7BB1, .4);
        }

        &.swiper-button-disabled{
            cursor: auto;
            opacity: .5;

            &:hover{
                background-color: rgba(#4C4948, .4);
            }
        }
    }

    .button_prev {
        position: absolute;
        top: 80px;
        left: -20px;
        z-index: 99;
        width: 40px;
        height: 40px;
        background-color: rgba(#4C4948, .4);
        background-image: url(../img/icon_gt.png);
        background-size: 6px 12px;
        background-repeat: no-repeat;
        background-position: center center;
        transform: scale(-1, 1); /* 左右反転 */
        cursor: pointer;

        &:hover{
            background-color: rgba(#5E7BB1, .4);
        }

        &.swiper-button-disabled{
            cursor: auto;
            opacity: .5;

            &:hover{
                background-color: rgba(#4C4948, .4);
            }
        }
    }
}




.education01_graph{
    position: relative;
    max-width: 700px;
    min-height: 380px;
    margin: 0 auto;

    .chart01{
        max-width: 380px;
        margin: 0 auto;

        @include media_query(){
            max-width: 300px;
        }
    }

    .img01_1{
        position: absolute;
        right: 0;
        top: 120px;
        max-width: 275px;

        @include media_query(){
            max-width: 150px;
        }

        img{
            width: 100%;
            height: auto;
        }
    }

    .img01_2{
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 123px;

        img{
            width: 100%;
            height: auto;
        }
    }
}




.under_title{
    text-align: center;

    span{
        @include rem(40);
        letter-spacing: .04em;
        color: $main-color;
        border-bottom: solid 3px #D1AE00;
        padding-bottom: 10px;
        line-height: 1.3;

        @include media_query(){
            display: block;
            @include rem(30);
        }

        small{
            @include rem(28);
        }
    }
}

.business01{
    .page_main{
        max-width: 765px;
        margin: 70px auto 0;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        @include media_query(){
            justify-content: center;
            margin: 50px 0 0;
        }

        img{
            width: 100%;
            height: auto;
        }

        .img01{
            padding-bottom: 10px;
            margin-right: auto;

            @include media_query(){
                width: 100%;
                margin-bottom: 10px;
                text-align: center;
            }

            img{
                max-width: 193px;
            }
        }

        .img02{
            margin-right: 45px;

            @include media_query(){
                margin-right: 30px;
            }

            img{
                max-width: 153px;

                @include media_query(){
                    max-width: 150px;
                }
            }
        }

        .img03{
            img{
                max-width: 114px;

                @include media_query(){
                    max-width: 90px;
                }
            }
        }

        .img04{
            @include media_query(){
                width: 100%;
                margin-bottom: 10px;
                text-align: center;
            }

            img{
                max-width: 200px;
            }
        }

        .img05{
            margin-left: auto;

            @include media_query(){
                margin: 20px 0 0 0;
            }

            img{
                max-width: 512px;
            }
        }
    }

    .office_info{
        display: flex;
        gap: 60px;
        margin-top: 80px;

        @include media_query(){
            display: block;
        }

        .office_img{
            max-width: 410px;

            img{
                width: 100%;
                height: auto;
            }
        }

        .office_text{

            @include media_query(){
                margin-top: 30px;
            }

            table{
                tr{
                    th,td{
                        @include rem(16);
                        line-height: 1.75;
                        padding: 10px 0;
                        text-align: left;
                    }

                    th{
                        white-space: nowrap;
                    }

                    td{
                        padding-left: 30px;
                    }
                }
            }
        }
    }
}

.business02{
    margin-top: 80px;

    .business02_wrap{
        margin-top: 65px;

        p{
            @include rem(18);
            line-height: 1.8;
        }

        .article_title{
            margin-bottom: 30px;
        }

        .business02_images{
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 60px;

            @include media_query(){
                margin-top: 30px;
            }

            img{
                width: 100%;
                height: auto;
            }

            .img01{
                img{
                    max-width: 136px;
                }
            }

            .img02{
                margin-left: 70px;

                @include media_query(){
                    margin: 0 20px;
                }

                img{
                    max-width: 126px;
                }
            }

            .img03{
                margin-left: 20px;

                @include media_query(){
                    margin: 0;
                }

                img{
                    max-width: 193px;
                }
            }
        }
    }
}

.combined{
    margin-top: 65px;

    .combined_inner{
        padding: 35px 30px;
        background-color: #E7F1FC;
        border-radius: 10px;

        @include media_query(){
            padding: 30px 20px;
        }

        .combined_title{
            border-radius: 10px;
            background-color: #66A581;
            text-align: center;
            @include rem(26);
            color: #fff;
            padding: 15px;
            margin-bottom: 55px;

            @include media_query(){
                line-height: 1.6;
                margin-bottom: 30px;
            }
        }

        .combined_imgs{
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 100%;
                height: auto;
            }

            .img07{
                max-width: 199px;
            }

            .img08{
                max-width: 64px;
                margin: 40px 45px 0;

                @include media_query(){
                    margin: 30px;
                }
            }

            .img09{
                max-width: 199px;
            }
        }

        .combined_arrow{
            text-align: center;

            .img10{
                img{
                    width: 100%;
                    max-width: 164px;
                    height: auto;

                    @include media_query(){
                        max-width: 120px;
                    }
                }
            }
        }

        .combined_result{
            text-align: center;
            margin-top: -30px;

            @include media_query(){
                margin-top: -10px;
            }

            .img11{
                img{
                    width: 100%;
                    max-width: 503px;
                    height: auto;
                }
            }
        }

        .btn_area{
            display: flex;
            align-items: flex-end;
            margin-top: 60px;

            @include media_query(){
                flex-direction: column;
            }

            .btn_area_wrap{
                width: 50%;

                @include media_query(){
                    width: 100%;

                    &:first-child{
                        order: 1;
                    }
                }

                & + .btn_area_wrap{
                    @include media_query(){
                        margin-bottom: 30px;
                    }
                }

                p{
                    position: relative;
                    display: flex;
                    align-items: flex-end;
                    max-width: 365px;
                    margin: 0 auto 20px;
                    @include rem(16);
                    text-align: center;
                    line-height: 1.6;
                    padding: 0 20px;

                    @include media_query(){
                        @include rem(13);
                    }

                    &::before{
                        position: absolute;
                        left: 0;
                        bottom: 10px;
                        content: "";
                        width: 1px;
                        height: 50px;
                        background-color: #231815;
                        transform: rotate(-30deg);
                    }

                    &::after{
                        position: absolute;
                        right: 0;
                        bottom: 10px;
                        content: "";
                        width: 1px;
                        height: 50px;
                        background-color: #231815;
                        transform: rotate(30deg);
                    }
                }

                .btn_black{
                    margin: 0;
                }
            }

            .btn_area_wrap{

            }
        }
    }
}

.voice{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 8.4%;
    margin-top: 120px;

    @include media_query(){
        grid-template-columns: 1fr;
        margin-top: 60px;
    }

    .voice_item{
        position: relative;
        border: solid 1px #4C4948;
        border-radius: 10px;

        &::before {
            content: "";
            position: absolute;
            top: -24px;
            left: 50%;
            margin-left: -15px;
            border: 12px solid transparent;
            border-bottom: 12px solid #FFF;
            z-index: 2;
        }

        &::after {
            content: "";
            position: absolute;
            top: -28px;
            left: 50%;
            margin-left: -17px;
            border: 14px solid transparent;
            border-bottom: 14px solid #4C4948;
            z-index: 1;
        }

        h2{
            padding: 0 25px;
            text-align: center;
            @include rem(21);
            padding: 25px 0;
            border-bottom: solid 1px #4C4948;
        }

        .voice_item_inner{
            padding: 24px;

            .btn_black{
                margin: 45px 0 0;
            }
        }

        img{
            width: 100%;
            height: auto;
            vertical-align: bottom;
        }
    }
}

.btn_black{
    margin-top: 80px;

    @include media_query(){
        margin-top: 50px;
    }

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        height: 64px;
        margin: 0 auto;
        background-color: #4C4948;
        border-radius: 30px;
        @include rem(17);
        text-decoration: none;
        color: #fff;
        background-image: url(../img/icon_gt.png);
        background-repeat: no-repeat;
        background-position: center right 20px;
        background-size: 6px 13px;

        transition: opacity .25;

        @include media_query(){
            @include rem(15);
            height: 58px;
        }

        &:hover{
            opacity: .7;
        }
    }
}

.btn_blue{

    a{
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 300px;
        height: 64px;
        margin: 0 auto;
        background-color: #89B9EE;
        border-radius: 30px;
        @include rem(17);
        text-decoration: none;
        color: #fff;
        background-image: url(../img/icon_gt.png);
        background-repeat: no-repeat;
        background-position: center right 20px;
        background-size: 6px 13px;

        transition: opacity .25s;

        @include media_query(){
            @include rem(15);
            height: 58px;
        }

        &:hover{
            opacity: .7;
        }
    }
}



.coreworking{
    margin-top: 120px;

    @include media_query(){
        margin-top: 80px;
    }

    h3{
        border: solid 2px $main-color;
        border-radius: 20px 20px 0 0;
        padding: 20px;
        text-align: center;
        color: $main-color;
        @include rem(26);

        @include media_query(){
            @include rem(20);
            padding: 15px;
        }
    }

    .coreworking_inner{
        border: solid 2px $main-color;
        border-top: none;
        border-radius: 0 0 20px 20px;
        padding: 35px 6.111% 70px;

        @include media_query(){
            padding: 30px 15px;
        }
    }

    .coreworking_inner_head{
        display: flex;
        align-items: center;

        @include media_query(){
            flex-direction: column;
        }

        p{
            flex: 1;
            margin: 0 60px 0 0;
            @include rem(18);
            line-height: 1.6;

            @include media_query(){
                order: 1;
                margin: 30px 0 0 0;
            }
        }

        img{
            max-width: 228px;

            @include media_query(){
                max-width: 200px;
            }
        }
    }

    .coreworking_inner_imgs{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6.5%;
        margin-top: 35px;

        @include media_query(){
            grid-template-columns: 1fr;
            grid-template-rows: auto;
        }

        .img_item{
            img{
                width: 100%;
                height: auto;
            }
        }
    }
}

.sp_br{
    display: none;

    @include media_query(){
        display: block;
    }
}




.life03_graph{
    position: relative;
    max-width: 700px;
    min-height: 380px;
    margin: 0 auto;

    .chart01{
        max-width: 380px;
        margin: 0 auto;

        @include media_query(){
            max-width: 300px;
        }
    }

    .img01_1{
        position: absolute;
        right: 0;
        top: 120px;
        max-width: 275px;

        @include media_query(){
            max-width: 150px;
        }

        img{
            width: 100%;
            height: auto;
        }
    }

    .img01_2{
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 123px;

        img{
            width: 100%;
            height: auto;
        }
    }
}



.system_links{
    margin-top: 120px;

    .article_title{

    }
}

.system_links_wrap{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 60px;
    margin-top: 55px;

    @include media_query(){
        grid-template-columns: 1fr;
        margin: 30px 0 0;
        gap: 30px;
    }

    .system_links_item{
        background-color: $main-color;
        padding: 40px 50px;
        border-radius: 10px;

        h4{
            @include rem(24);
            text-align: center;
            color: #fff;
            line-height: 1.5;
        }
        
        p{
            @include rem(18);
            color: #fff;
            margin-top: 25px;
            line-height: 1.5;
        }

        .btn_black{
            margin-top: 30px;

            a{
                background-color: #89B9EE;
            }
        }
    }
}

.figcaption{
    text-align: center;
    @include rem(14);

    figcaption{
        margin-top: 10px;
    }
}
